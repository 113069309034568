export const ADD_METHOD_REQUEST = 'savvi/billing/ADD_METHOD_REQUEST';
export const ADD_METHOD_SUCCESS = 'savvi/billing/ADD_METHOD_SUCCESS';
export const ADD_METHOD_FAILED = 'savvi/billing/ADD_METHOD_FAILED';
export const ADD_METHODV2_REQUEST = 'savvi/billing/ADD_METHODV2_REQUEST';
export const ADD_METHODV2_SUCCESS = 'savvi/billing/ADD_METHODV2_SUCCESS';
export const ADD_METHODV2_FAILED = 'savvi/billing/ADD_METHODV2_FAILED';

export const ADD_ACH_REQUEST = 'savvi/billing/ADD_ACH_REQUEST';
export const ADD_ACH_SUCCESS = 'savvi/billing/ADD_ACH_SUCCESS';
export const ADD_ACH_FAILED = 'savvi/billing/ADD_ACH_FAILED';

export const GET_PLAID_TOKEN_REQUEST = 'savvi/billing/GET_PLAID_TOKEN_REQUEST';
export const GET_PLAID_TOKEN_SUCCESS = 'savvi/billing/GET_PLAID_TOKEN_SUCCESS';
export const GET_PLAID_TOKEN_FAILED = 'savvi/billing/GET_PLAID_TOKEN_FAILED';

export const SAVE_PLAID_REQUEST = 'savvi/billing/SAVE_PLAID_REQUEST';
export const SAVE_PLAID_SUCCESS = 'savvi/billing/SAVE_PLAID_SUCCESS';
export const SAVE_PLAID_FAILED = 'savvi/billing/SAVE_PLAID_FAILED';

export const VERIFY_ACH_REQUEST = 'savvi/billing/VERIFY_ACH_REQUEST';
export const VERIFY_ACH_SUCCESS = 'savvi/billing/VERIFY_ACH_SUCCESS';
export const VERIFY_ACH_FAILED = 'savvi/billing/VERIFY_ACH_FAILED';

export const CLEAR_ERRORS = 'savvi/billing/CLEAR_ERRORS';
export const SET_ERROR = 'savvi/billing/SET_ERROR';

export const REMOVE_METHOD_REQUEST = 'savvi/billing/REMOVE_METHOD_REQUEST';
export const REMOVE_METHOD_SUCCESS = 'savvi/billing/REMOVE_METHOD_SUCCESS';
export const REMOVE_METHOD_FAILED = 'savvi/billing/REMOVE_METHOD_FAILED';
export const REMOVE_METHOD = 'savvi/billing/REMOVE_METHOD';
export const REMOVE_METHODV2_REQUEST = 'savvi/billing/REMOVE_METHODV2_REQUEST';
export const REMOVE_METHODV2_SUCCESS = 'savvi/billing/REMOVE_METHODV2_SUCCESS';
export const REMOVE_METHODV2_FAILED = 'savvi/billing/REMOVE_METHODV2_FAILED';
export const REMOVE_METHODV2 = 'savvi/billing/REMOVE_METHODV2';

export const GET_METHODS_REQUEST = 'savvi/billing/GET_METHODS_REQUEST';
export const GET_METHODS_SUCCESS = 'savvi/billing/GET_METHODS_SUCCESS';
export const GET_METHODS_FAILED = 'savvi/billing/GET_METHODS_FAILED';
export const GET_METHODSV2_REQUEST = 'savvi/billing/GET_METHODSV2_REQUEST';
export const GET_METHODSV2_SUCCESS = 'savvi/billing/GET_METHODSV2_SUCCESS';
export const GET_METHODSV2_FAILED = 'savvi/billing/GET_METHODSV2_FAILED';

export const GET_INVOICE_REQUEST = 'savvi/billing/GET_INVOICE_REQUEST';
export const GET_INVOICE_SUCCESS = 'savvi/billing/GET_INVOICE_SUCCESS';
export const GET_INVOICE_FAILED = 'savvi/billing/GET_INVOICE_FAILED';
export const SET_INVOICE = 'savvi/billing/SET_INVOICE';
export const GET_INVOICEV3_REQUEST = 'savvi/billing/GET_INVOICEV3_REQUEST';
export const GET_INVOICEV3_SUCCESS = 'savvi/billing/GET_INVOICEV3_SUCCESS';
export const GET_INVOICEV3_FAILED = 'savvi/billing/GET_INVOICEV3_FAILED';
export const SET_INVOICEV3 = 'savvi/billing/SET_INVOICEV3';

export const GET_SUBSCRIPTION_REQUEST = 'savvi/billing/GET_SUBSCRIPTION_REQUEST';
export const GET_SUBSCRIPTION_SUCCESS = 'savvi/billing/GET_SUBSCRIPTION_SUCCESS';
export const GET_SUBSCRIPTION_FAILED = 'savvi/billing/GET_SUBSCRIPTION_FAILED';
export const MERGE_SUBSCRIPTION = 'savvi/billing/SET_SUBSCRIPTION';
export const GET_SUBSCRIPTIONV2_REQUEST = 'savvi/billing/GET_SUBSCRIPTIONV2_REQUEST';
export const GET_SUBSCRIPTIONV2_SUCCESS = 'savvi/billing/GET_SUBSCRIPTIONV2_SUCCESS';
export const GET_SUBSCRIPTIONV2_FAILED = 'savvi/billing/GET_SUBSCRIPTIONV2_FAILED';
export const MERGE_SUBSCRIPTIONV2 = 'savvi/billing/MERGE_SUBSCRIPTIONV2';
export const SET_METHODS_BY_PARTNER = 'savvi/billing/SET_METHODS_BY_PARTNER';

export const SET_SUBSCRIPTION_REQUEST = 'savvi/billing/SET_SUBSCRIPTION_REQUEST';
export const SET_SUBSCRIPTION_SUCCESS = 'savvi/billing/SET_SUBSCRIPTION_SUCCESS';
export const SET_SUBSCRIPTION_FAILED = 'savvi/billing/SET_SUBSCRIPTION_FAILED';
export const SET_SUBSCRIPTIONV2_REQUEST = 'savvi/billing/SET_SUBSCRIPTIONV2_REQUEST';
export const SET_SUBSCRIPTIONV2_SUCCESS = 'savvi/billing/SET_SUBSCRIPTIONV2_SUCCESS';
export const SET_SUBSCRIPTIONV2_FAILED = 'savvi/billing/SET_SUBSCRIPTIONV2_FAILED';

export const DELETE_SUBSCRIPTION_REQUEST = 'savvi/billing/DELETE_SUBSCRIPTION_REQUEST';
export const DELETE_SUBSCRIPTION_SUCCESS = 'savvi/billing/DELETE_SUBSCRIPTION_SUCCESS';
export const DELETE_SUBSCRIPTION_FAILED = 'savvi/billing/DELETE_SUBSCRIPTION_FAILED';

export const GET_SUBSCRIPTION_PREVIEW_REQUEST =
  'savvi/billing/GET_SUBSCRIPTION_PREVIEW_REQUEST';
export const GET_SUBSCRIPTION_PREVIEW_SUCCESS =
  'savvi/billing/GET_SUBSCRIPTION_PREVIEW_SUCCESS';
export const GET_SUBSCRIPTION_PREVIEW_FAILED =
  'savvi/billing/GET_SUBSCRIPTION_PREVIEW_FAILED';
export const SET_SUBSCRIPTION_PREVIEW = 'savvi/billing/SET_SUBSCRIPTION_PREVIEW';

export const GET_COUPON_INFO_REQUEST = 'savvi/billing/GET_COUPON_INFO_REQUEST';
export const GET_COUPON_INFO_SUCCESS = 'savvi/billing/GET_COUPON_INFO_SUCCESS';
export const GET_COUPON_INFO_FAILED = 'savvi/billing/GET_COUPON_INFO_FAILED';
export const SET_COUPON_INFO = 'savvi/billing/SET_COUPON_INFO';

export const GET_MERCURY_BANKS_REQUEST = 'savvi/billing/GET_MERCURY_BANKS_REQUEST';
export const GET_MERCURY_BANKS_SUCCESS = 'savvi/billing/GET_MERCURY_BANKS_SUCCESS';
export const GET_MERCURY_BANKS_FAILED = 'savvi/billing/GET_MERCURY_BANKS_FAILED';

export const SET_DEFAULT_REQUEST = 'savvi/billing/SET_DEFAULT_REQUEST';
export const SET_DEFAULT_SUCCESS = 'savvi/billing/SET_DEFAULT_SUCCESS';
export const SET_DEFAULT_FAILED = 'savvi/billing/SET_DEFAULT_FAILED';
export const SET_DEFAULTV2_REQUEST = 'savvi/billing/SET_DEFAULTV2_REQUEST';
export const SET_DEFAULTV2_SUCCESS = 'savvi/billing/SET_DEFAULTV2_SUCCESS';
export const SET_DEFAULTV2_FAILED = 'savvi/billing/SET_DEFAULTV2_FAILED';

export const SET_MERCURY_BANKS = 'savvi/billing/SET_MERCURY_BANKS';

export const SET_STRIPE_DOMAIN_SLUG = 'savvi/billing/SET_STRIPE_DOMAIN_SLUG';
export const CLEAR_STRIPE_DOMAIN_SLUG = 'savvi/billing/CLEAR_STRIPE_DOMAIN_SLUG';
