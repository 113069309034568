import React, { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import { InputFeedback, InputHelp, InputSublabel } from '..';

import './InputStyledTextField.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '../../common';

const bem = elementName => `styled-text-field${elementName ? '__' + elementName : ''}`;

const TextField = forwardRef(
  (
    {
      autoComplete,
      autoFocus,
      customClass,
      defaultPlaceholder,
      placeholder,
      error,
      handleOnCopyClick,
      inputClassName,
      isCopyable,
      isDataHidden,
      isDisabled,
      isFetching,
      isFixed,
      isRequired,
      questionHelp,
      label,
      myType,
      name,
      onKeyDown,
      onKeyPress,
      onFocus,
      onBlur,
      onChange,
      readOnly,
      savedValue,
      sublabel,
      type,
      value,
    },
    ref,
  ) => {
    const defaultRef = useRef(null);
    const inputRef = ref || defaultRef;
    const inputPlaceholder = placeholder || defaultPlaceholder;
    let updatedVal = value;
    if (updatedVal === null || updatedVal === undefined) {
      updatedVal = '';
    }
    updatedVal = updatedVal.trimStart();

    const [isActive, setIsActive] = useState(
      !!updatedVal || (!updatedVal && inputPlaceholder),
    );
    const [isAutofilledWithoutVal, setIsAutofilledWithoutVal] = useState(false);

    const wrapperClasses = classnames(bem('wrapper'), {
      [bem('wrapper--error')]: !!error && !isAutofilledWithoutVal,
      [bem('wrapper--hidden')]: isDataHidden,
      [bem('wrapper--copyable')]: isCopyable,
      [bem('wrapper--percent')]: myType === 'percent',
      [bem('wrapper--disabled')]: isFixed || isDisabled,
      [bem('wrapper--padded-top')]: label.length > 100,
    });

    useEffect(() => {
      const trimVal = value.trim();
      if (!isActive && (trimVal || (!trimVal && inputPlaceholder))) {
        setIsActive(true);
      }
    }, [isActive, value, inputPlaceholder]);

    useEffect(() => {
      /**
       * The field can be prefilled on the very first page loading by the browser
       * By the security reason browser limits access to the field value from JS level and the value becomes available
       * only after first user interaction with the page
       * So, even if the input value shows as empty
       * it actually can have some value, which is why this is needed
       */
      const checkAutofilled = () => {
        const doc = document.getElementById('email');
        const autofilled = !!doc?.matches('*:-webkit-autofill');
        setIsAutofilledWithoutVal(autofilled && !doc.value);
      };
      // The time when it's ready is not very stable, so check few times
      const timeout1 = setTimeout(checkAutofilled, 500);
      const timeout2 = setTimeout(checkAutofilled, 1000);
      const timeout3 = setTimeout(checkAutofilled, 2000);

      return () => {
        clearTimeout(timeout1);
        clearTimeout(timeout2);
        clearTimeout(timeout3);
      };
    }, []);

    return (
      <div
        className={`${bem('container')} ${
          isDataHidden ? bem('container--hidden') : ''
        } ${customClass}`}
      >
        <div className={wrapperClasses} onClick={handleOnCopyClick}>
          {label && (
            <label
              className={classnames(bem('label'), {
                [bem('label--active')]: isActive || isAutofilledWithoutVal,
              })}
              htmlFor={name}
            >
              {label}
              {!!isRequired && <span className="workbench-form__required">*</span>}
              {questionHelp && <InputHelp text={questionHelp} />}
            </label>
          )}
          <input
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            readOnly={readOnly}
            className={classnames(inputClassName, bem())}
            disabled={isFixed || isDisabled || isFetching}
            // data-lpignore={isDataHidden ? 'true' : 'false'}
            id={name}
            onKeyDown={onKeyDown}
            onKeyPress={ev => {
              setIsAutofilledWithoutVal(false);
              if (onKeyPress) {
                onKeyPress(ev);
              } else if (ev.key === 'Enter') {
                onBlur(ev);
              }
            }}
            onFocus={ev => {
              if (isAutofilledWithoutVal && !!ev.target.value) {
                setIsAutofilledWithoutVal(false);
              }
              setIsActive(true);
              if (ev.target.value !== value) {
                onChange(ev);
              }
              onFocus(ev);
            }}
            onBlur={ev => {
              if (isAutofilledWithoutVal && !!ev.target.value) {
                setIsAutofilledWithoutVal(false);
              }
              if (!ev.target.value) {
                setIsActive(false);
              }
              if (ev.target.value.trim() === savedValue) {
                return;
              }
              onBlur(ev);
            }}
            onChange={ev => {
              setIsAutofilledWithoutVal(false);
              onChange(ev);
            }}
            placeholder={placeholder || defaultPlaceholder}
            ref={inputRef}
            type={type}
            value={updatedVal}
            name={name}
          />
          {isCopyable && (
            <>
              <FontAwesomeIcon className={bem('copy-icon')} icon={['fal', 'copy']} />
              <Tooltip text="Copy Text" align="center" />
            </>
          )}
          {myType === 'percent' && (
            <div className={bem('percent-icon')}>
              <FontAwesomeIcon icon={['far', 'percent']} />
            </div>
          )}
        </div>
        <InputSublabel text={sublabel} />
        {!isAutofilledWithoutVal && <InputFeedback error={error} />}
      </div>
    );
  },
);

TextField.propTypes = {
  isDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  type: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.array]),
  inputClassName: PropTypes.string,
  isRequired: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  sublabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

TextField.defaultProps = {
  autoComplete: 'on',
  customClass: '',
  isDisabled: false,
  error: null,
  handleOnCopyClick: () => {},
  name: 'input',
  type: 'text',
  icon: false,
  isRequired: false,
  label: '',
  placeholder: '',
  sublabel: null,
  value: '',
  onKeyDown: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onChange: val => {},
};

export default TextField;
