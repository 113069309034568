import {
  CLEAR_ERRORS,
  MERGE_SUBSCRIPTION,
  MERGE_SUBSCRIPTIONV2,
  REMOVE_METHOD,
  SET_ERROR,
  SET_INVOICE,
  SET_INVOICEV3,
  SET_SUBSCRIPTION_PREVIEW,
  SET_METHODS_BY_PARTNER,
  SET_MERCURY_BANKS,
  SET_STRIPE_DOMAIN_SLUG,
  CLEAR_STRIPE_DOMAIN_SLUG,
} from './types';

export function removeMethod(methodId) {
  return {
    type: REMOVE_METHOD,
    payload: { methodId },
  };
}

export function setInvoice(payload) {
  return {
    type: SET_INVOICE,
    payload,
  };
}

export function setInvoiceV3(payload) {
  return {
    type: SET_INVOICEV3,
    payload,
  };
}

export function mergeSubscription(payload) {
  return {
    type: MERGE_SUBSCRIPTION,
    payload,
  };
}
export function mergeSubscriptionV2(payload) {
  return {
    type: MERGE_SUBSCRIPTIONV2,
    payload,
  };
}

export function setSubscriptionPreview(payload) {
  return {
    type: SET_SUBSCRIPTION_PREVIEW,
    payload,
  };
}

export function setMethodsByPartner(payload, partnerSlug) {
  return {
    type: SET_METHODS_BY_PARTNER,
    payload,
    partnerSlug,
  };
}

export function setError(payload) {
  return {
    type: SET_ERROR,
    payload,
  };
}

export function clearErrors() {
  return {
    type: CLEAR_ERRORS,
  };
}

export const setStripeDomainSlug = selectedSlug => {
  sessionStorage.setItem('stripeDomainSlug', selectedSlug);

  return {
    type: SET_STRIPE_DOMAIN_SLUG,
    selectedSlug,
  };
};

export const clearStripeDomainSlug = () => ({ type: CLEAR_STRIPE_DOMAIN_SLUG });

export function setAccountMercuryBanks(payload) {
  return {
    type: SET_MERCURY_BANKS,
    payload,
  };
}
