import { combineReducers } from 'redux';

import { apiErrorsReducer, createReducer, fetchingReducer } from '../../utils';
import {
  ADD_ACH_SUCCESS,
  ADD_METHOD_SUCCESS,
  // ADD_METHODV2_SUCCESS,
  GET_PLAID_TOKEN_SUCCESS,
  GET_METHODS_SUCCESS,
  GET_METHODSV2_SUCCESS,
  MERGE_SUBSCRIPTION,
  MERGE_SUBSCRIPTIONV2,
  REMOVE_METHOD,
  REMOVE_METHODV2,
  SET_DEFAULT_SUCCESS,
  SET_INVOICE,
  SET_INVOICEV3,
  SET_MERCURY_BANKS,
  SET_METHODS_BY_PARTNER,
  GET_SUBSCRIPTION_PREVIEW_SUCCESS,
  VERIFY_ACH_SUCCESS,
  SET_STRIPE_DOMAIN_SLUG,
  CLEAR_STRIPE_DOMAIN_SLUG,
} from './types';

const isFetching = fetchingReducer('billing');

const plaidTokenReducer = createReducer('')({
  [GET_PLAID_TOKEN_SUCCESS]: (state, { payload }) => payload.link_token,
});

const methodsReducer = createReducer([])({
  [GET_METHODS_SUCCESS]: (state, { payload }) => {
    return payload.methods;
  },
  [ADD_METHOD_SUCCESS]: (state, { payload }) => payload.methods,
  [SET_DEFAULT_SUCCESS]: (state, { payload }) => payload.methods,
  [REMOVE_METHOD]: (state, { payload }) =>
    state.filter(method => method.id !== payload.methodId),
});

const methodsV2Reducer = createReducer({})({
  [GET_METHODSV2_SUCCESS]: (state, { payload }) => {
    return payload;
  },
  // [ADD_METHODV2_SUCCESS]: (state, { payload }) => payload,
  // [SET_DEFAULTV2_SUCCESS]: (state, { payload }) => payload,
  [SET_METHODS_BY_PARTNER]: (state, { payload, partnerSlug }) => {
    return {
      ...state,
      [partnerSlug]: payload,
    };
  },
  [REMOVE_METHODV2]: (state, { payload }) =>
    state.filter(method => method.id !== payload.methodId),
});

const defaultMethodReducer = createReducer('')({
  [GET_METHODS_SUCCESS]: (state, { payload }) => payload.default,
  [ADD_METHOD_SUCCESS]: (state, { payload }) => payload.default,
  [SET_DEFAULT_SUCCESS]: (state, { payload }) => payload.default,
});

const invoiceReducer = createReducer([])({
  [SET_INVOICE]: (state, { payload }) => payload,
});

const invoiceV3Reducer = createReducer({})({
  [SET_INVOICEV3]: (state, { payload }) => payload,
});

const subscriptionReducer = createReducer({})({
  [MERGE_SUBSCRIPTION]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
});
const subscriptionV2Reducer = createReducer({})({
  [MERGE_SUBSCRIPTIONV2]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
});

// const defaultStripeKeysDict = {
//   paperos: 'pk_test_EkK8QpA6HsXQnc3LYvLFrwmh',
//   develop: 'pk_test_5gkLdQ08xKKLC3sdiujD9zpT',
// };

const stripeDomainSlugReducer = createReducer(
  sessionStorage.getItem('stripeDomainSlug') || 'paperos',
)({
  [SET_STRIPE_DOMAIN_SLUG]: (state, action) => action.selectedSlug,
  [CLEAR_STRIPE_DOMAIN_SLUG]: (state, action) => action.selectedSlug,
});

const subscriptionPreviewReducer = createReducer({})({
  [GET_SUBSCRIPTION_PREVIEW_SUCCESS]: (state, { payload }) => ({ ...payload.invoice }),
});

const achReducer = createReducer({})({
  [ADD_ACH_SUCCESS]: (state, { payload }) => ({ ...state, payload }),
  [VERIFY_ACH_SUCCESS]: (state, { payload }) => ({ ...state, payload }),
});

const accountMercuryBanksReducer = createReducer([])({
  [SET_MERCURY_BANKS]: (state, { payload }) => [...payload],
});

const isSynced = createReducer(false)({});

export default combineReducers({
  isFetching,
  methods: methodsReducer,
  methodsV2: methodsV2Reducer,
  invoices: invoiceReducer,
  invoicesV3: invoiceV3Reducer,
  subscription: subscriptionReducer,
  subscriptionV2: subscriptionV2Reducer,
  subscriptionPreview: subscriptionPreviewReducer,
  ach: achReducer,
  plaidToken: plaidTokenReducer,
  apiErrors: apiErrorsReducer('billing'),
  defaultMethod: defaultMethodReducer,
  accountMercuryBanks: accountMercuryBanksReducer,
  stripeDomainSlug: stripeDomainSlugReducer,
  isSynced,
});
