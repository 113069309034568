import { useEffect, useState } from 'react';
import classnames from 'classnames';
import InputStyledCheckbox from '../common/InputStyledCheckbox';
import { Button, MyModal, TooltipAction } from '../common';
import { useDispatch, useSelector } from 'react-redux';

import './SettingsBilling.scss';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
  ApiErrors,
  IsFetching,
  PaymentMethodsV3,
  PlaidToken,
  StripeDomainSlug,
} from '../../redux/modules/Billing/selectors';
import {
  addACHMethod,
  addPaymentMethod,
  getPaymentMethods,
  getPlaidToken,
  removeACHMethod,
  removePaymentMethodV2,
  savePlaidMethod,
  setDefaultMethod,
  verifyACHMethod,
} from '../../redux/modules/Billing/operations';
// import { BrandMeta } from '../../redux/modules/UI/selectors';
import { AccountInfo } from '../../redux/modules/Formation/selectors';
import { isEmpty } from 'lodash-es';
import { setNotice } from '../../redux/modules/UI/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik } from 'formik';
import { Select, StyledCheckbox, TextField } from '../formik';
import { TwoCharCountryData } from '../../data/CountryData';
import { validateSelect, validateString } from '../../utils/FeatureTypes';
import { ReactComponent as PlaidLogo } from '../../assets/images/plaid_logo.svg';

/** Plaid Link React SDK */
import { usePlaidLink } from 'react-plaid-link';
import { setStripeDomainSlug } from '../../redux/modules/Billing/actions';

const bem = elementName => `billing${elementName ? '__' + elementName : ''}`;

const brandIcons = {
  'American Express': 'amex',
  MasterCard: 'mastercard',
  Discover: 'discover',
  Visa: 'visa',
};

const partnerStripeDict = {
  paperos: ['PaperOS', process.env.REACT_APP_STRIPE_KEY],
  develop: ['Develop', process.env.REACT_APP_TEST_STRIPE_KEY],
  rams: ['Rams', process.env.REACT_APP_RAMS_TEST_STRIPE_KEY],
};

const SettingsBilling = ({ initialized }) => {
  const dispatch = useDispatch();
  // const methods = useSelector(PaymentMethodsWithDefault);
  // const brandMeta = useSelector(BrandMeta);
  // const brandStripeDomain = !!(brandMeta.assets || {}).stripe_domain;
  const partnerMethods = useSelector(PaymentMethodsV3);
  const plaidToken = useSelector(PlaidToken);
  const accountInfo = useSelector(AccountInfo);
  const { has_partner_subscriptions } = accountInfo;

  const [isModalOpen, toggleModal] = useState(false);
  const [selectedMethodIndex, setSelectedMethodIndex] = useState(0);
  const [modalType, setModalType] = useState('new');
  const [isPartnersFetched, setIsPartnersFetched] = useState(false);
  const stripeDomainSlug = useSelector(StripeDomainSlug);

  // const stripePromise = useMemo(() => {
  //   return loadStripe(partnerStripeDict[stripeDomainSlug]);
  // }, [stripeDomainSlug]);

  // const [selectedPartner, setSelectedPartnerMethods] = useState('develop');
  const partnerMethod = partnerMethods[stripeDomainSlug] || {};
  const { methods = [] } = partnerMethod;
  let defaultMethodIndex = (methods || []).findIndex(e => e.is_default);
  if (defaultMethodIndex === -1) {
    defaultMethodIndex = 0;
  }

  const onSaveDefault = index => {
    const selectedMethod = methods[index];
    if (methods[defaultMethodIndex].id !== selectedMethod.id) {
      dispatch(setDefaultMethod(selectedMethod.id, stripeDomainSlug));
    }
  };

  const onActionClick = (actionType, index) => {
    if (actionType === 'verify') {
      setModalType('verify_ach');
      setSelectedMethodIndex(index);
      toggleModal(true);
    }
    if (actionType === 'remove') {
      const { brand, last4, id } = methods[index];
      const message = `Are you sure you want to delete the following card? \n\n${
        brand || 'Bank Account'
      } •••• ${last4} \n`;

      if (window.confirm(message)) {
        if (!brand) {
          return dispatch(removeACHMethod(id)).then(e => toggleModal(false));
        }
        return dispatch(removePaymentMethodV2(id, stripeDomainSlug)).then(e =>
          toggleModal(false),
        );
      }
    }
  };

  const handleModal = cardType => {
    toggleModal(!isModalOpen);
    setModalType(cardType);
  };

  useEffect(() => {
    if (!isPartnersFetched && !initialized) {
      dispatch(getPaymentMethods(true)).then(p => {
        setIsPartnersFetched(true);
        if (!p[stripeDomainSlug]) {
          dispatch(setStripeDomainSlug('paperos'));
        }
      });
    }
  }, [dispatch, initialized, isPartnersFetched, stripeDomainSlug]);

  useEffect(() => {
    if (!plaidToken) {
      dispatch(getPlaidToken());
    }
  }, [dispatch, plaidToken]);

  const isDefaultStripeDomain = stripeDomainSlug === 'paperos';

  return (
    <>
      <h2>
        Payment Methods For {partnerStripeDict[stripeDomainSlug][0]}
        {isDefaultStripeDomain && (
          <TooltipAction
            text="We recommend setting up an ACH payment method to avoid any additional transaction and processing fees that may occur when using a credit card."
            toggleIcon={['fal', 'question-circle']}
            position="right"
            align="left"
          />
        )}
      </h2>
      {/* {Object.keys(partnerMethods).length > 0 && (
        <Button
          buttonType="link"
          onClick={() =>
            dispatch(setStripeDomainSlug(isDefaultStripeDomain ? 'develop' : 'paperos'))
          }
        >
          Switch Stripe Domain to {isDefaultStripeDomain ? 'Develop Test' : 'PaperOS'}
        </Button>
      )} */}
      {methods.length === 0 && (
        <div className={bem('empty')}>
          <h3>Setup a payment method below.</h3>
        </div>
      )}
      {!!has_partner_subscriptions && (
        <p className={bem('sublabel')}>
          <FontAwesomeIcon icon="exclamation-circle" /> You are connected to a partner
          that covers certain subscription features & workflows credit usage & only need
          to add a payment method for any workflows or document credits not covered by
          them.
        </p>
      )}
      <div className={bem('methods')}>
        {methods.map((e, index) => (
          <PaymentMethodCard
            key={`billing-method-${index}`}
            method={e}
            index={index}
            defaultMethodIndex={defaultMethodIndex}
            onSaveDefault={onSaveDefault}
            onActionClick={onActionClick}
            methodsLength={methods.length}
          />
        ))}
      </div>
      <div className={bem('actions')}>
        <Button buttonType="secondary" size="sm" onClick={e => handleModal('new')}>
          Add Card Payment Method
        </Button>
        <div className={bem('actionCol')}>
          <Button size="sm" onClick={e => handleModal('add_ach')}>
            Add ACH Payment Method
          </Button>
          {!!plaidToken && isDefaultStripeDomain && (
            <PlaidAction
              plaidToken={plaidToken}
              onSuccess={payload => dispatch(getPaymentMethods())}
            />
          )}
        </div>
      </div>
      <MethodModal
        isDefaultLocked={methods.length === 0}
        isOpen={isModalOpen}
        modalType={modalType}
        method={methods[selectedMethodIndex]}
        onActionClick={onActionClick}
        onClose={() => toggleModal(false)}
        selectedMethodIndex={selectedMethodIndex}
        stripeDomainSlug={stripeDomainSlug}
      />
    </>
  );
};

export const PlaidAction = ({ plaidToken, onSuccess }) => (
  <>
    {!plaidToken && (
      <Button size="sm" className={bem('plaidAction')} buttonType="link" isDisabled>
        Connect Bank Via <PlaidLogo />
      </Button>
    )}
    {!!plaidToken && <PlaidLink plaidToken={plaidToken} onSuccess={onSuccess} />}
  </>
);

const PlaidLink = ({ plaidToken, onSuccess }) => {
  const dispatch = useDispatch();
  // The usePlaidLink hook manages Plaid Link creation
  // It does not return a destroy function;
  // instead, on unmount it automatically destroys the Link instance
  const config = {
    onSuccess: (public_token, metadata) => {
      const { institution, account } = metadata;
      dispatch(
        savePlaidMethod({
          public_token,
          institution,
          account,
        }),
      ).then(payload => onSuccess(payload));
    },
    onExit: (err, metadata) => {
      if (err != null) {
        console.error(err);
      }
    },
    token: plaidToken,
    //required for OAuth; if not using OAuth, set to null or omit:
    // receivedRedirectUri: window.location.href,
  };

  const { open, ready } = usePlaidLink(config);
  return (
    <Button
      buttonType="link"
      className={bem('plaidAction')}
      size="sm"
      isDisabled={!ready}
      onClick={open}
    >
      Connect Bank Via <PlaidLogo />
    </Button>
  );
};

export const PaymentMethodCard = ({
  method: {
    id,
    brand,
    exp_month,
    exp_year,
    funding,
    last4,
    metadata: { bank_label } = {},
    status,
  },
  index,
  isDisabled,
  isPlans,
  methodsLength,
  onSaveDefault,
  onActionClick,
  defaultMethodIndex,
}) => {
  const name = `method-check-${index}`;
  const statusLabel =
    (status === 'new' && 'verification needed') ||
    (status === 'verified' && 'verified') ||
    (status === 'verification_failed' && 'verification failed') ||
    (funding === 'debit' && 'debit') ||
    '';

  const checkLabel =
    (isPlans &&
      ((defaultMethodIndex === index && 'Selected Payment Method') ||
        'Select Payment Method')) ||
    (defaultMethodIndex === index && 'Default Payment Method') ||
    'Save as Default Method';

  return (
    <div className={bem('cardWrapper')}>
      <InputStyledCheckbox
        checked={index === defaultMethodIndex}
        value={index}
        type="radio"
        isDisabled={status === 'new' || isDisabled}
        label={checkLabel}
        onClick={e => onSaveDefault(index)}
        name={name}
      />
      <div className={bem('card')}>
        <div className={bem('cardRow')}>
          {brand && (
            <FontAwesomeIcon
              className={bem('cardBrand')}
              icon={['fab', `cc-${brandIcons[brand]}`]}
            />
          )}
          {!brand && <h4>Bank Account</h4>}
          {status === 'new' && (
            <Button
              size="xs"
              isDisabled={isDisabled}
              onClick={e => onActionClick('verify', index)}
            >
              Verify ACH
            </Button>
          )}
          {status !== 'new' && (
            <h4
              className={classnames(bem('cardMethod'), {
                [bem('cardMethod--warning')]: status === 'verification_failed',
              })}
            >
              {statusLabel}
            </h4>
          )}
        </div>
        <h2 className={bem('cardDetail')}>**** **** **** {last4}</h2>
        <div className={bem('cardRow')}>
          <h4 className={bem('cardExp')}>
            {exp_month ? `${exp_month}/${exp_year}` : bank_label}
          </h4>
          {methodsLength > 1 && (
            <Button
              buttonType="icon"
              isDisabled={isDisabled}
              isWarning
              size="sm"
              tooltip="Remove Method"
              onClick={e => onActionClick('remove', index)}
            >
              <FontAwesomeIcon icon={['fal', 'trash-alt']} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const MethodModal = ({
  selectedMethodIndex,
  isDefaultLocked,
  isOpen,
  method = {},
  modalType = 'new',
  onActionClick,
  onClose,
  stripeDomainSlug,
}) => {
  const dispatch = useDispatch();
  const { account_holder_name, id, routing_number, last4 } = method;

  const stripe = useStripe();
  const elements = useElements();

  const isFetching = useSelector(IsFetching);
  const apiErrors = useSelector(ApiErrors);

  const [submitting, setSubmitting] = useState(false);
  const [stripeError, setStripeError] = useState(false);

  const initVals =
    (modalType === 'new' && {
      name: '',
      country: { value: 'US', label: 'United States of America (the)' },
      is_default: !!isDefaultLocked,
    }) ||
    (modalType === 'add_ach' && {
      account_holder_name: '',
      account_holder_type: 'individual',
      country: { value: 'US', label: 'United States of America (the)' },
      currency: 'usd',
      routing_number: '',
      account_number: '',
      bank_label: '',
    }) ||
    (modalType === 'verify_ach' && {
      account_holder_name,
      routing_number,
      account_number: '.....' + last4,
      amount_one: '',
      amount_two: '',
      is_default: !!isDefaultLocked,
    }) ||
    {};

  const handleSubmit = async ({
    amount_one,
    amount_two,
    bank_label,
    is_default,
    ...submittedVals
  }) => {
    if (
      is_default === 'true' ||
      is_default === '1' ||
      is_default === 1 ||
      (typeof is_default === 'boolean' && !!is_default)
    ) {
      is_default = true;
    } else {
      is_default = false;
    }
    setSubmitting(true);
    if (!submittedVals.name && !submittedVals.account_holder_name) {
      setSubmitting(false);
      return dispatch(
        setNotice({
          type: 'warning',
          message: 'Please provide a name for your payment card',
        }),
      );
    }
    const cardElement = elements.getElement(CardElement);
    let updatedVals = submittedVals;
    if (updatedVals.country) {
      updatedVals.country = updatedVals.country.value;
    }

    if (modalType === 'verify_ach') {
      return dispatch(
        verifyACHMethod(
          {
            amounts: [amount_one, amount_two],
            bankToken: id,
          },
          is_default,
        ),
      ).then(
        e => {
          if (e && !e.error) {
            setSubmitting(false);
            onClose();
          }
        },
        error => setSubmitting(false),
      );
    }
    let { token, error } = await stripe.createToken(
      modalType === 'add_ach' ? 'bank_account' : cardElement,
      {
        ...updatedVals,
      },
    );

    if (!error) {
      let addMethod = addPaymentMethod;
      if (modalType === 'add_ach') {
        token = { ...token, bank_label, stripe_domain: stripeDomainSlug };
        addMethod = addACHMethod;
      }

      await dispatch(addMethod(token, is_default, null, stripeDomainSlug)).then(
        e => {
          setSubmitting(false);
          if (e && !e.error) {
            onClose();
          }
        },
        error => {
          setSubmitting(false);
        },
      );
    } else {
      if (error.type === 'validation_error') {
        setStripeError(error.message);
      } else if (error.type === 'invalid_request_error') {
        setStripeError(error.message);
      } else {
        setStripeError(
          'Stripe is detecting an error in your information, please recheck your inputs.',
        );
        console.info('catch', error);
      }
      setSubmitting(false);
    }
  };

  let modalAction = 'Add Payment Method';
  let modalDescription = '';
  let nameLabel = 'Name on Card';
  if (modalType === 'add_ach') {
    modalAction = 'Add ACH Payment';
    modalDescription =
      'Once you submit your ACH account information you will receive two micro-transactions into your account after 2-3 days. You will need to enter these amounts to verify your bank account.';
    nameLabel = 'Name';
  } else if (modalType === 'verify_ach') {
    modalAction = 'Verify ACH Payment';
    modalDescription =
      'After you submitted your ACH account information you should receive two micro-transactions into your account after 2-3 days. You will need to enter these amounts to verify your bank account.';
    nameLabel = 'Name';
  }
  const isDefaultLabel = isDefaultLocked
    ? 'Card will be saved as default payment method'
    : 'Use as default payment method';

  return (
    <MyModal
      isOpen={isOpen}
      onRequestClose={() => {
        setStripeError('');
        onClose();
      }}
      className={bem('modal')}
      overlayClassName={bem('modalOverlay')}
    >
      <h1>{modalAction}</h1>
      <h4>{modalDescription}</h4>
      <Formik initialValues={initVals} onSubmit={handleSubmit}>
        <Form>
          {modalType === 'new' && (
            <CardElement
              className={bem('stripeContainer')}
              options={{
                hideIcon: true,
                style: {
                  base: {
                    fontSize: '18px',
                    '::placeholder': {
                      color: '#afbdc5',
                      fontWeight: 400,
                      fontSize: '16px',
                      fontStyle: 'italic',
                    },
                  },
                },
              }}
            />
          )}
          {modalType !== 'verify_ach' && (
            <div className={bem('inputRow')}>
              <Field
                component={TextField}
                label={nameLabel}
                placeholder={'John Doe'}
                name={(modalType === 'new' && 'name') || 'account_holder_name'}
                isRequired
                validate={validateString}
              />
              <Field
                component={Select}
                isClearable={false}
                name="country"
                options={TwoCharCountryData}
                label="Country"
                validate={validateSelect}
              />
            </div>
          )}
          {modalType === 'add_ach' && (
            <>
              <Field
                component={TextField}
                name="routing_number"
                label="Routing Number"
                placeholder="e.g. 110000000"
                myType="numerical"
                isRequired
                validate={validateString}
              />
              <Field
                component={TextField}
                name="account_number"
                label="Bank Account Number"
                placeholder="e.g. 000123456789"
                myType="numerical"
                isRequired
                validate={validateString}
              />
              <Field
                component={TextField}
                name="bank_label"
                label="Name your Bank Account"
                validate={validateString}
              />
            </>
          )}
          {modalType === 'verify_ach' && (
            <>
              <Field
                component={TextField}
                name="account_holder_name"
                placeholder={'John Doe'}
                label="Account Holder Name / Company Name"
                icon="user"
                isDisabled
              />
              <Field
                component={TextField}
                name="routing_number"
                label="Routing Number"
                myType="numerical"
                isDisabled
              />
              <Field
                component={TextField}
                name="account_number"
                label="Bank Account Number"
                myType="numerical"
                isDisabled
              />
              <div className={bem('inputRow')}>
                <Field
                  component={TextField}
                  name="amount_one"
                  label="Amount One"
                  myType="float"
                  defaultPlaceholder="E.g., 0.32"
                  isRequired
                  validate={validateString}
                  questionHelp="Log in to your bank account to check your transactions. You will see two incoming deposits under a dollar, enter those two amounts below."
                  icon={['fal', 'dollar-sign']}
                />
                <Field
                  component={TextField}
                  name="amount_two"
                  label="Amount Two"
                  myType="float"
                  defaultPlaceholder="E.g. 0.45"
                  isRequired
                  validate={validateString}
                  icon={['fal', 'dollar-sign']}
                />
              </div>
            </>
          )}
          {modalType !== 'add_ach' && (
            <div className={bem('inputRow')}>
              <Field
                component={StyledCheckbox}
                name="is_default"
                isDisabled={isDefaultLocked}
                label={isDefaultLabel}
              />
              {modalType === 'verify_ach' && (
                <Button
                  buttonType="icon"
                  isWarning
                  disabled={isFetching}
                  tooltip="Delete ACH Method"
                  onClick={() => onActionClick('remove', selectedMethodIndex)}
                >
                  <FontAwesomeIcon
                    icon={isFetching ? 'spinner' : 'trash'}
                    spin={isFetching}
                  />
                </Button>
              )}
            </div>
          )}
          {!isEmpty(stripeError) && (
            <div className={bem('stripeError')}>
              <h4>
                <strong>Stripe Error: </strong> {stripeError}
              </h4>
            </div>
          )}
          {!isEmpty(apiErrors.message) && (
            <div className={bem('stripeError')}>
              <h4>
                <strong>Error: </strong> {stripeError}
              </h4>
            </div>
          )}
          <div className={bem('modalActions')}>
            <Button
              buttonType="secondary"
              onClick={() => {
                setStripeError('');
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              isFetching={submitting || isFetching}
              isDisabled={submitting || isFetching}
              type="submit"
            >
              {modalAction}
            </Button>
          </div>
        </Form>
      </Formik>
    </MyModal>
  );
};

export default SettingsBilling;
