import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clone from 'lodash-es/clone';
import compact from 'lodash-es/compact';

import { operations } from '../../redux/modules/User';

import './RegisterConfirm.scss';

class RegisterConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: ['', '', '', '', '', '', '', ''],
      isFilled: false,
    };
    this.inputs = [];
  }

  handleResend = () => {
    const message = 'Send new confirmation code?';

    if (window.confirm(message)) {
      this.props.resendEmailConfirmation();
    }
  };

  updateCode(index, value) {
    const code = clone(this.state.code);
    code[index] = value;
    this.setState({
      code,
    });

    if (this.inputs[index + 1] && value !== '') {
      this.inputs[index + 1].focus();
    }
    if (compact(code).length === this.inputs.length) {
      this.submitCode(code);
    }
  }

  // focusOnNextDigit(index){

  // }

  allowOverwrite(index, event) {
    const { key } = event;
    if (this.state.code[index].match(/\d/) && key.match(/\d/)) {
      const code = clone(this.state.code);
      code[index] = '';
      // let changed = false;
      // let moveFocus = false
      this.setState({
        code,
      });
    }
    if (key === 'Backspace' && this.state.code[index] === '') {
      if (this.inputs[index - 1]) {
        this.inputs[index - 1].focus();
      }
    }
  }

  handlePaste(digit, event) {
    event.preventDefault();
    const numbers = event.clipboardData.getData('Text').replace(/[^0-9]/g, '');
    const digits = numbers.split('');

    let code = clone(this.state.code);
    digits.forEach((value, index) => {
      const input = digit + index;
      if (input < this.inputs.length) {
        this.inputs[input].value = value;
        code[input] = value;
        if (input < this.inputs.length - 1) {
          this.inputs[input + 1].focus();
        }
      }
    });
    this.setState({ code });
    if (compact(code).length === this.inputs.length) {
      this.submitCode(code);
    }
  }

  submitCode = code => {
    const { confirmUserEmail } = this.props;
    if (typeof code !== 'string') {
      code = code.join('');
    }
    if (code.indexOf('-' === -1)) {
      code = [code.slice(0, 3), '-', code.slice(3)].join('');
    }
    confirmUserEmail(code);
  };

  renderDigit = digit => {
    return (
      <div key={`digit-wrapper-${digit}`} className="digit__wrapper">
        <input
          type="number"
          disabled={this.props.isLoading}
          onPaste={e => this.handlePaste(digit, e)}
          key={`digit-${digit}`}
          ref={input => (this.inputs[digit] = input)}
          className="input__digit"
          value={this.state.code[digit]}
          maxLength={1}
          onChange={e => this.updateCode(digit, e.target.value)}
          onKeyDown={e => this.allowOverwrite(digit, e)}
        />
      </div>
    );
  };

  render() {
    const { isLoading, email, errors } = this.props;

    return (
      <div className="register-confirm">
        <div className="confirm-email__content">
          <h2 className="page__heading page__heading--large">Please check your email.</h2>
          <h4 className="confirm-email__instructions">
            Thank you for creating an account with PaperOS, We sent an email to
            <span> {email}</span>. Enter the code contained within that email below.
          </h4>
          <label className="confirm-email__label">Enter your Code</label>
          <form className="form__confirm-email">
            <div className="digit__group">{[0, 1, 2].map(this.renderDigit)}</div>
            <div className="digit__span">—</div>
            <div className="digit__group">{[3, 4, 5].map(this.renderDigit)}</div>
          </form>
          {errors.token && (
            <div className="confirm-email__info">
              <p>
                <FontAwesomeIcon icon="exclamation-circle" size="2x" />
              </p>
              <p>That code wasn't valid. Double Check and try again.</p>
            </div>
          )}
          {isLoading && (
            <div className="confirm-email__loading">
              <FontAwesomeIcon icon="spinner" spin /> <br />
              Checking your code...
            </div>
          )}
          {!errors.token && !isLoading && (
            <p className="text--center">
              Keep this window open while you are checking your email for the code from{' '}
              <a href="mailto:support@paperos.com">support@paperos.com</a>. if you can't
              locate the email, look in your spam folder, or check above to see if you
              input your email correctly.
            </p>
          )}
          <h4 className="text--center">
            Can't find/code not working?{' '}
            <button
              type="button"
              className="confirm-email__link"
              onClick={this.handleResend}
            >
              Send New Confirmation Code
            </button>
          </h4>
          <h4 className="text--center">
            Enter the wrong Email?{' '}
            <Link to="/register" onClick={() => this.props.onLogout()}>
              Back to Registration
            </Link>
          </h4>
        </div>
      </div>
    );
  }
}

RegisterConfirm.defaultProps = {
  isLoading: false,
  errors: {},
  email: '',
};

const mapStateToProps = ({ Auth }) => {
  return {
    isLoading: Auth.isFetching,
    errors: Auth.errors,
    email: Auth.user.email || '',
  };
};

const mapDispatchToProps = (dispatch, { history: { push } }) => {
  const { confirmUserEmail, onLogout, resendEmailConfirmation } = operations;
  return {
    confirmUserEmail: code => dispatch(confirmUserEmail(code, push)),
    onLogout: () => dispatch(onLogout()),
    resendEmailConfirmation: () => dispatch(resendEmailConfirmation()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterConfirm);
