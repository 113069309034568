import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { getAccountProject } from '../../redux/modules/Project/operations';
import { AccountProject } from '../../redux/modules/Project/selectors';
import { BrandMeta } from '../../redux/modules/UI/selectors';
import handlePath from '../../utils/handlePath';

import './BatchUploadModal.scss';
import { Button, MyModal } from '../common';
import { requestHeaders } from '../../redux/utils/api';
import { BoxToken } from '../../redux/modules/Cabinet/selectors';
import FileUploader from '../FileUploader/FileUploader';
import {
  createTransaction,
  generateBatchTransactions,
  getTransaction,
  getTransactionQuestionnaire,
} from '../../redux/modules/Transaction/operations';
import {
  getOrderedQuestions,
  isFetchingWithHooks,
} from '../../redux/modules/Transaction/selectors';
import { setNotice } from '../../redux/modules/UI/actions';

const BatchUploadModal = ({ params }) => {
  const { companyId, moduleId, projectId, transactionId } = params;
  const dispatch = useDispatch();
  const history = useHistory();
  const { replace, push } = history;
  const location = useLocation();
  const { pathname } = location;
  const project = useSelector(State => AccountProject(State, projectId));
  const isFetching = useSelector(isFetchingWithHooks);
  const companyBoxToken = useSelector(BoxToken);
  const questions = useSelector(State =>
    getOrderedQuestions(State.Transaction, transactionId),
  );
  const brandMeta = useSelector(BrandMeta);

  let specificKeyInfo = useMemo(() => {
    let arr = [];
    let isSocialSecurityKeyShowing = false;
    let isCurrencyKeyShowing = false;
    let isAddressKeyShowing = false;
    questions.forEach(q => {
      if (
        q.innerQuestions.findIndex(
          innerQ => innerQ.feature_type.feature_type === 'social_security',
        ) !== -1
      ) {
        isSocialSecurityKeyShowing = true;
      }
      if (
        q.innerQuestions.findIndex(innerQ =>
          innerQ.feature_type.feature_type.includes('currency'),
        ) !== -1
      ) {
        isCurrencyKeyShowing = true;
      }
      if (
        q.innerQuestions.findIndex(innerQ =>
          innerQ.feature_type.feature_type.includes('address'),
        ) !== -1
      ) {
        isAddressKeyShowing = true;
      }
      if (q.resource_type_id !== 1) {
        arr.push({
          label: `For every item on the "${q.name}.${q.innerQuestions[0].name}*" column please input "${q.resourceArr[0].resource_name}"`,
          value: q.resourceArr[0].resource_name,
          slug: q.name,
          resource: q.resource_custom_label,
          isCopyable: true,
        });
      }
    });
    arr.push({
      label: 'Any header marked with "*" is a required input.',
      slug: 'required_inputs',
      resource: 'Investor',
    });
    if (isSocialSecurityKeyShowing) {
      arr.push({
        label:
          "Social Security Key is not marked as required, but make sure to provide if they're a US citizen.",
        slug: 'social_security',
        resource: 'Investor',
      });
    }
    if (isCurrencyKeyShowing) {
      arr.push({
        label: 'Please add "$" to the start of any currency input"',
        slug: 'currency',
        resource: 'Investor',
      });
    }
    if (isAddressKeyShowing) {
      arr.push({
        label: 'address:',
        slug: 'address',
        resource: 'Investor',
        isAddressKey: true,
      });
    }
    return arr;
  }, [questions]);

  const {
    template: { actions = [] },
    transactions,
  } = project;

  const { label, id: templateId } = actions[0] || {};
  const startedActionIndex = transactions.findIndex(
    e => !!e.open && e.template.id === templateId,
  );
  const { id } = transactions[startedActionIndex] || {};
  const isIncorrectTransactionId = Number(id) !== Number(transactionId);

  const [selectedBoxFileId, setSelectedBoxFileId] = useState(null);
  const [failedLines, setFailedLines] = useState([]);
  const [isKeyShowing, setKeyShowing] = useState(true);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (pathname.includes('/batch-upload') && !isMounted) {
      setIsMounted(true);
      if (!id) {
        dispatch(createTransaction(projectId, templateId)).then(
          payload => {
            dispatch(getTransactionQuestionnaire(payload.id));
            // dispatch(getTransaction(payload.id));
            dispatch(getAccountProject(projectId));
            replace(
              handlePath(
                `/workbench/${moduleId}/${projectId}/${payload.id}/batch-upload`,
                companyId,
              ),
            );
          },
          error =>
            replace(
              handlePath(`/workbench/${moduleId}/${projectId}/0/initialize`, companyId),
            ),
        );
      } else {
        dispatch(getTransaction(id));
        dispatch(getTransactionQuestionnaire(id));
        if (isIncorrectTransactionId) {
          replace(
            handlePath(
              `/workbench/${moduleId}/${projectId}/${id}/batch-upload`,
              companyId,
            ),
          );
        }
      }
    }
  }, [
    dispatch,
    pathname,
    id,
    isIncorrectTransactionId,
    isMounted,
    projectId,
    replace,
    templateId,
    moduleId,
    companyId,
  ]);

  const onClose = () => {
    setFailedLines([]);
    push(
      handlePath(
        `/workbench/${moduleId}/${projectId}/${transactionId}/portal`,
        companyId,
      ),
    );
  };

  const handleCopyValue = value => {
    navigator.clipboard.writeText(value);
    dispatch(setNotice('Input value copied'));
  };

  const handleDownloadTemplate = ev => {
    const url = `${brandMeta.web_base_url}/api/account/transaction/${templateId}/batch`;
    var client = new XMLHttpRequest();
    client.open('GET', url);
    client.withCredentials = true;
    client.responseType = 'blob';
    Object.entries(requestHeaders(true)).forEach(([key, value]) => {
      client.setRequestHeader(key, value);
    });
    client.onreadystatechange = () => {
      switch (client.readyState) {
        case 4:
          const windowUrl = window.URL.createObjectURL(client.response);
          const link = document.createElement('a');
          link.href = windowUrl;
          link.target = '_blank';
          link.download = `${label} Batch Upload Template.csv`;
          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(windowUrl);
            document.body.removeChild(link);
          }, 0);
          break;
        default:
      }
    };
    client.send();
  };

  const handleGenerateBatchTransactions = () => {
    if (failedLines.length > 0) {
      return onClose();
    }
    setFailedLines([]);
    dispatch(generateBatchTransactions(templateId, selectedBoxFileId, projectId)).then(
      p => {
        dispatch(getAccountProject(projectId));
        const failedLineArr = p.failed_lines || [];
        if (failedLineArr.length > 0) {
          setFailedLines(failedLineArr);
          dispatch(
            setNotice('Batch Upload Submitted with some rows failing to generate tasks.'),
          );
        } else {
          dispatch(setNotice('Investor Tasks Generated!'));
          onClose();
        }
      },
    );
  };

  return (
    <MyModal
      isOpen={pathname.includes('/batch-upload')}
      onRequestClose={onClose}
      contentLabel={'Batch Upload by CSV'}
      className="BatchUploadModal"
      overlayClassName="BatchUploadModal__overlay"
    >
      <div className="BatchUploadModal__header">
        <h1>{label} Batch Upload</h1>
        {/* <h2></h2> */}
        <Button buttonType="link" onClick={handleDownloadTemplate}>
          <FontAwesomeIcon icon={['fal', 'file-download']} />
          Download CSV Template
        </Button>
        <FontAwesomeIcon
          className="BatchUploadModal__exit"
          onClick={onClose}
          icon="times"
        />
      </div>
      <div className="BatchUploadModal__key">
        {isKeyShowing && (
          <>
            <h3>
              <FontAwesomeIcon icon="exclamation-circle" />
              {'Important Form-Specific Formatting: ('}
              <Button
                buttonType="link"
                size="sm"
                onClick={ev => setKeyShowing(!isKeyShowing)}
              >
                {isKeyShowing ? 'hide' : 'show'}
              </Button>
              {')'}
            </h3>
            <ul>
              {specificKeyInfo.map(key => (
                <li key={`key-${key.resource}-${key.slug}`}>
                  {key.label}
                  {key.isCopyable && (
                    <Button
                      buttonType="icon"
                      size="sm"
                      tooltip="Copy Input Value"
                      onClick={ev => handleCopyValue(key.value)}
                    >
                      <FontAwesomeIcon icon={['fal', 'clipboard']} />
                    </Button>
                  )}
                  {key.isAddressKey && (
                    <div className="BatchUploadModal__keyAddress">
                      <div className="BatchUploadModal__keyAddressCol">
                        <p>Line One</p>
                        <p>Line Two</p>
                        <p>City, State/Province/Region, Zip/Postal Code</p>
                        <p>Country</p>
                      </div>
                      <div className="BatchUploadModal__keyAddressDivider" />
                      <div className="BatchUploadModal__keyAddressCol">
                        <p>810 S Spring St Apt 1105</p>
                        <br />
                        <p>Los Angeles, California, 90014</p>
                        <p>United States of America</p>
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <div className="BatchUploadModal__body">
        <div className="BatchUploadModal__upload">
          <h2>Select your batch upload file:</h2>
          <FileUploader
            boxToken={companyBoxToken}
            fileId={selectedBoxFileId}
            filename={`${label} Batch Upload ${new Intl.DateTimeFormat().format(
              new Date(),
            )}`}
            path="/Miscellaneous"
            usePathAsRootFolder
            onUpload={fileId => setSelectedBoxFileId(fileId)}
            tags={['batch-upload-file']}
            account_transaction_id={transactionId}
          />
        </div>
        {failedLines.length > 0 && (
          <div className="BatchUploadModal__errors">
            <h4>Failed to generate tasks for the following rows:</h4>
            <ul>
              {failedLines.map(line => (
                <li key={`failed-line-${line.row}-${line.missing_fields}`}>
                  <span>{`Row ${line.row}`}</span>
                  {`: missing ${line.missing_fields}`}
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="BatchUploadModal__actions">
          <Button
            buttonType="secondary"
            isDisabled={failedLines.length > 0}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            isDisabled={!selectedBoxFileId || isFetching}
            isFetching={isFetching}
            onClick={handleGenerateBatchTransactions}
          >
            {failedLines.length > 0 ? 'Close' : 'Generate Tasks'}
          </Button>
        </div>
      </div>
    </MyModal>
  );
};

export default BatchUploadModal;
