import { RSAA } from 'redux-api-middleware';

import get from 'lodash-es/get';

import { requestHeaders } from '../../utils/api';
import { getAccountInfo } from '../Formation/operations';
import { setNotice } from '../UI/actions';
import {
  mergeSubscription,
  setInvoice,
  setAccountMercuryBanks,
  mergeSubscriptionV2,
  setInvoiceV3,
  // setMethodsByPartner,
} from './actions';
import * as types from './types';

const addPaymentMethod = (token, is_default, customNotice, stripeDomainSlug) => {
  if (!!stripeDomainSlug && stripeDomainSlug !== 'paperos') {
    return addPaymentMethodV2(token, is_default, customNotice, stripeDomainSlug);
  }
  const body = { stripe_token_id: token.id, is_default };
  const {
    ADD_METHOD_REQUEST: REQUEST,
    ADD_METHOD_SUCCESS: SUCCESS,
    ADD_METHOD_FAILED: FAILED,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/admin/billing/method',
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
        options: {
          timeout: 5000,
        },
      },
    });
    if (!actionResponse.error) {
      dispatch(getPaymentMethods());
      dispatch(setNotice(customNotice || 'Payment Method Added.'));
      return await actionResponse;
    }
    const message =
      get(actionResponse, 'payload.err.message', false) ||
      get(
        actionResponse,
        'payload.message',
        'An error has occurred. If error persists after page refresh, please message our Support Team.',
      );

    dispatch(
      setNotice({
        type: 'error',
        message,
      }),
    );
    return await actionResponse;
  };
};
const addPaymentMethodV2 = (token, is_default, customNotice, stripeDomainSlug) => {
  let body = {
    stripe_token_id: token.id,
    is_default,
    stripe_domain: stripeDomainSlug,
  };

  const {
    ADD_METHODV2_REQUEST: REQUEST,
    ADD_METHODV2_SUCCESS: SUCCESS,
    ADD_METHODV2_FAILED: FAILED,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/v3/admin/billing/method',
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
        options: {
          timeout: 5000,
        },
      },
    });
    if (!actionResponse.error) {
      dispatch(getPaymentMethodsV2());
      dispatch(setNotice(customNotice || 'Payment Method Added.'));
      return await actionResponse;
    }
    const message =
      get(actionResponse, 'payload.err.message', false) ||
      get(
        actionResponse,
        'payload.message',
        'An error has occurred. If error persists after page refresh, please message our Support Team.',
      );

    dispatch(
      setNotice({
        type: 'error',
        message,
      }),
    );
    return await actionResponse;
  };
};

const addACHMethod = (token, is_default) => {
  const {
    ADD_ACH_REQUEST: REQUEST,
    ADD_ACH_SUCCESS: SUCCESS,
    ADD_ACH_FAILED: FAILED,
  } = types;

  const body = JSON.stringify(token);

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/ach',
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body,
      },
    });
    if (!actionResponse.error) {
      await dispatch(getPaymentMethods());
      await dispatch(setNotice('ACH Account info submitted.'));
      return actionResponse;
    }
    const message =
      get(actionResponse, 'payload.err.message', false) ||
      get(
        actionResponse,
        'payload.message',
        'An error has occurred. If error persists after page refresh, please message our Support Team.',
      );
    dispatch(
      setNotice({
        type: 'error',
        message,
      }),
    );
    return actionResponse;
  };
};

/**
 *
 * @param {Object} body
 * @param {String} body.public_token - Public Plaid Token
 * @param {Object} body.institution - bank institution data
 * @param {Object} body.account - bank account data
 * @returns {Promise} - returns payload.source_id
 */
export const savePlaidMethod = body => {
  const {
    SAVE_PLAID_REQUEST: REQUEST,
    SAVE_PLAID_SUCCESS: SUCCESS,
    SAVE_PLAID_FAILED: FAILED,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/admin/billing/plaid/public_token',
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      await dispatch(setNotice('Account Saved.'));
      return await actionResponse.payload;
    }
    throw new Error(actionResponse.payload?.message || 'An error has occurred.');
  };
};

export const getPlaidToken = () => {
  const {
    GET_PLAID_TOKEN_REQUEST: REQUEST,
    GET_PLAID_TOKEN_SUCCESS: SUCCESS,
    GET_PLAID_TOKEN_FAILED: FAILED,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/admin/billing/plaid/link_token',
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await actionResponse.payload;
    }
    throw new Error(actionResponse.payload?.message || 'An error has occurred.');
  };
};

const verifyACHMethod = ({ bankToken, ...body }, is_default) => {
  const {
    VERIFY_ACH_REQUEST: REQUEST,
    VERIFY_ACH_SUCCESS: SUCCESS,
    VERIFY_ACH_FAILED: FAILED,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/ach/${bankToken}`,
        method: 'PUT',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      await dispatch(getPaymentMethods());
      await dispatch(setNotice('ACH Account verified.'));
      return await actionResponse;
    }

    await dispatch(
      setNotice({
        type: 'error',
        message: actionResponse.payload?.message || 'An error has occurred.',
      }),
    );
    throw new Error(actionResponse.payload?.message || 'An error has occurred.');
  };
};

const removePaymentMethod = (methodId, stripeDomainSlug) => {
  if (stripeDomainSlug) {
    return removePaymentMethodV2(methodId, stripeDomainSlug);
  }
  const {
    REMOVE_METHOD_REQUEST: REQUEST,
    REMOVE_METHOD_SUCCESS: SUCCESS,
    REMOVE_METHOD_FAILED: FAILED,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/billing/method/${methodId}`,
        method: 'DELETE',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(getPaymentMethods());
      dispatch(setNotice('Payment Method removed.'));
      return actionResponse;
    }
    return actionResponse;
  };
};
const removePaymentMethodV2 = (methodId, stripeDomainSlug) => {
  const {
    REMOVE_METHODV2_REQUEST: REQUEST,
    REMOVE_METHODV2_SUCCESS: SUCCESS,
    REMOVE_METHODV2_FAILED: FAILED,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/v3/admin/billing/method/${methodId}/${stripeDomainSlug}`,
        method: 'DELETE',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(getPaymentMethods());
      dispatch(setNotice('Payment Method removed.'));
      return actionResponse;
    }
    return actionResponse;
  };
};

const removeACHMethod = methodId => {
  const {
    REMOVE_METHOD_REQUEST: REQUEST,
    REMOVE_METHOD_SUCCESS: SUCCESS,
    REMOVE_METHOD_FAILED: FAILED,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/ach/${methodId}`,
        method: 'DELETE',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(getPaymentMethods());
      await dispatch(setNotice('ACH Payment Method removed.'));
      return actionResponse;
    }
    return actionResponse;
  };
};

const setDefaultMethod = (methodId, stripeDomainSlug) => {
  if (stripeDomainSlug !== 'paperos') {
    return setDefaultMethodV2(methodId, stripeDomainSlug);
  }
  const {
    SET_DEFAULT_REQUEST: REQUEST,
    SET_DEFAULT_SUCCESS: SUCCESS,
    SET_DEFAULT_FAILED: FAILED,
  } = types;

  const body = { default_method_id: methodId };

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/billing/method`,
        method: 'PUT',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      await dispatch(setNotice('Default Method set.'));
      return await dispatch(getPaymentMethods());
    }
  };
};
const setDefaultMethodV2 = (methodId, stripeDomainSlug) => {
  const {
    SET_DEFAULTV2_REQUEST: REQUEST,
    SET_DEFAULTV2_SUCCESS: SUCCESS,
    SET_DEFAULTV2_FAILED: FAILED,
  } = types;

  let body = {
    default_method_id: methodId,
  };
  if (stripeDomainSlug) {
    body['stripe_domain'] = stripeDomainSlug;
  }

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/v3/admin/billing/method`,
        method: 'PUT',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      await dispatch(setNotice('Default Method set.'));
      return await dispatch(getPaymentMethods());
      // dispatch(setMethodsByPartner(actionResponse.payload, stripeDomainSlug));
    }
  };
};

const getPaymentMethods = passV2Payload => {
  const {
    GET_METHODS_REQUEST: REQUEST,
    GET_METHODS_SUCCESS: SUCCESS,
    GET_METHODS_FAILED: FAILED,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/billing/method',
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (passV2Payload) {
      return await dispatch(getPaymentMethodsV2());
    }
    dispatch(getPaymentMethodsV2());
    return await actionResponse.payload;
  };
};
const getPaymentMethodsV2 = () => {
  const {
    GET_METHODSV2_REQUEST: REQUEST,
    GET_METHODSV2_SUCCESS: SUCCESS,
    GET_METHODSV2_FAILED: FAILED,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/v3/billing/method',
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });

    return await actionResponse.payload;
  };
};

const getInvoice = () => {
  const {
    GET_INVOICE_REQUEST: REQUEST,
    GET_INVOICE_SUCCESS: SUCCESS,
    GET_INVOICE_FAILED: FAILED,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/invoice',
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(getInvoiceMulti());
      dispatch(setInvoice(actionResponse.payload));
      return await actionResponse.payload;
    }
  };
};

const getInvoiceMulti = () => {
  const {
    GET_INVOICEV3_REQUEST: REQUEST,
    GET_INVOICEV3_SUCCESS: SUCCESS,
    GET_INVOICEV3_FAILED: FAILED,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/v3/invoice',
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(setInvoiceV3(actionResponse.payload));
    }
  };
};

const getSubscriptions = () => {
  const {
    GET_SUBSCRIPTION_REQUEST: REQUEST,
    GET_SUBSCRIPTION_SUCCESS: SUCCESS,
    GET_SUBSCRIPTION_FAILED: FAILED,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/billing/customer',
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await dispatch(mergeSubscription(actionResponse.payload));
    }
  };
};
const getSubscriptionsV2 = () => {
  const {
    GET_SUBSCRIPTIONV2_REQUEST: REQUEST,
    GET_SUBSCRIPTIONV2_SUCCESS: SUCCESS,
    GET_SUBSCRIPTIONV2_FAILED: FAILED,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/v3/billing/customer',
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await dispatch(mergeSubscriptionV2(actionResponse.payload));
    }
  };
};

const setSubscription = (subscriptionSlug, selected_source_id) => {
  const body = selected_source_id ? JSON.stringify({ selected_source_id }) : null;
  const {
    SET_SUBSCRIPTION_REQUEST: REQUEST,
    SET_SUBSCRIPTION_SUCCESS: SUCCESS,
    SET_SUBSCRIPTION_FAILED: FAILED,
  } = types;
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/bundle/${subscriptionSlug}`,
        method: 'post',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body,
      },
    });

    if (!actionResponse.error) {
      dispatch(setNotice('Subscribed!'));
      dispatch(getAccountInfo());
      dispatch(getSubscriptions());
      return await actionResponse;
    } else {
      const message =
        get(actionResponse, 'payload.err.message', false) ||
        get(
          actionResponse,
          'payload.message',
          'An error has occurred. If error persists after page refresh, please message our Support Team.',
        );
      dispatch(
        setNotice({
          type: 'error',
          message,
        }),
      );
      throw actionResponse.payload.message;
    }
  };
};

const deleteSubscription = subscriptionId => {
  const {
    DELETE_SUBSCRIPTION_REQUEST: REQUEST,
    DELETE_SUBSCRIPTION_SUCCESS: SUCCESS,
    DELETE_SUBSCRIPTION_FAILED: FAILED,
  } = types;
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/bundle/${subscriptionId}`,
        method: 'delete',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice('Subscription cancelled'));
      dispatch(getSubscriptions());
      return actionResponse;
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
      throw actionResponse.payload.message;
    }
  };
};

const getSubscriptionPreview = subscriptionSlug => {
  const {
    GET_SUBSCRIPTION_PREVIEW_REQUEST: REQUEST,
    GET_SUBSCRIPTION_PREVIEW_SUCCESS: SUCCESS,
    GET_SUBSCRIPTION_PREVIEW_FAILED: FAILED,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/bundle/${subscriptionSlug}/preview`,
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return actionResponse;
      // dispatch(setSubscriptionPreview(actionResponse.payload));
    }
    // setNotice({ type: 'error', message: actionResponse.payload });
    throw actionResponse;
  };
};

const getCouponInfo = (subscriptionSlug, coupon) => {
  const {
    GET_COUPON_INFO_REQUEST: REQUEST,
    GET_COUPON_INFO_SUCCESS: SUCCESS,
    GET_COUPON_INFO_FAILED: FAILED,
  } = types;

  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/admin/bundle/${subscriptionSlug}/coupon`,
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify({ coupon }),
      },
    });
    if (!actionResponse.error) {
      return actionResponse;
    }
    setNotice({ type: 'error', message: actionResponse.payload });
    return actionResponse;
  };
};

const getAccountMercuryBanks = accountId => {
  const {
    GET_MERCURY_BANKS_REQUEST: REQUEST,
    GET_MERCURY_BANKS_SUCCESS: SUCCESS,
    GET_MERCURY_BANKS_FAILED: FAILED,
  } = types;
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/banks`,
        method: 'GET',
        headers: requestHeaders(true, accountId),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(setAccountMercuryBanks(actionResponse.payload));
      return await actionResponse.payload;
    } else {
      let err = new Error(actionResponse.payload?.message || 'An error has occurred.');
      throw err;
    }
  };
};

export {
  // getHistory,
  addACHMethod,
  addPaymentMethod,
  addPaymentMethodV2,
  deleteSubscription,
  getAccountMercuryBanks,
  getCouponInfo,
  getInvoice,
  getPaymentMethods,
  getPaymentMethodsV2,
  getSubscriptions,
  getSubscriptionsV2,
  getSubscriptionPreview,
  removePaymentMethod,
  removePaymentMethodV2,
  removeACHMethod,
  setDefaultMethod,
  setDefaultMethodV2,
  setSubscription,
  verifyACHMethod,
};
