import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useHistory, useLocation } from 'react-router';

import './PrivacyPolicyModal.scss';
import { MyModal } from '../common';
// Modal.setAppElement('#root');

const PrivacyPolicyModal = () => {
  const history = useHistory();
  const { action, goBack, replace } = history;
  const location = useLocation();
  const { pathname, state } = location;
  const { background } = state || {};

  const onClose = () => {
    if (action !== 'POP') {
      goBack();
    } else if (background) {
      replace(background);
    } else {
      replace('/c/0/company-select');
    }
  };

  return (
    <MyModal
      isOpen={pathname.includes('/privacy')}
      onRequestClose={onClose}
      contentLabel={'PaperOS Privacy Policy'}
      className="privacyPolicyModal"
      overlayClassName="privacyPolicyModal__overlay"
    >
      <div className="privacyPolicyModal__header">
        <h2>Savvi Technologies, Inc.</h2>
        <FontAwesomeIcon
          className="privacyPolicyModal__exit"
          onClick={onClose}
          icon="times"
        />
      </div>
      <h2>Privacy Policy</h2>
      <h3>Last Updated: October 11, 2021</h3>
      <p>
        This privacy policy (“<strong>Policy</strong>”) describes how Savvi Technologies,
        Inc. and its affiliates (“<strong>Company</strong>”) collect, use and share
        personal information of consumer users of this website,{' '}
        <a target="_blank" rel="noopener noreferrer" href="https://savvi.legal">
          www.savvi.legal
        </a>{' '}
        (the “<strong>Site</strong>”). This Policy also applies to any of our other
        websites that post this Policy. This Policy does not apply to websites that post
        different statements.
      </p>
      <div className="privacyPolicyModal__sectionHeader">
        <h3>What We Collect</h3>
      </div>
      <p>We get information about you in a range of ways</p>
      <div className="privacyPolicyModal__indented">
        <p>
          <strong>Information You Give Us.</strong> We may collect your name, address,
          email, phone number, fax number, username, password, demographic information
          (such as your gender and occupation), company information and other information
          related to your use of the products and services provided by the Company,
          including those provided via the Site, as well as other information you directly
          give us on our Site.
        </p>
        <p>
          <strong>Information We Get From Others.</strong> We may get information about
          you from other sources. We may add this to information we get from this Site.
        </p>
        <p>
          <strong>Information Automatically Collected.</strong> We automatically log
          information about you and your computer. For example, when visiting our Site, we
          log your computer operating system type, browser type, browser language, the
          website you visited before browsing to our Site, pages you viewed, how long you
          spent on a page, access times and information about your use of and actions on
          our Site.
        </p>
        <p>
          <strong>Cookies.</strong> We may log information using “cookies.” Cookies are
          small data files stored on your hard drive by a website. We may use both session
          Cookies (which expire once you close your web browser) and persistent Cookies
          (which stay on your computer until you delete them) to provide you with a more
          personal and interactive experience on our Site. This type of information is
          collected to make the Site more useful to you and to tailor the experience with
          us to meet your special interests and needs.
        </p>
      </div>
      <div className="privacyPolicyModal__sectionHeader">
        <h3>Use of Personal Information</h3>
      </div>
      <p>
        We may use your personal information to take some or all of the following actions:
      </p>
      <ul>
        <li>Operate, maintain, and improve our sites, products, and services;</li>
        <li>
          Send information including products, services, confirmations, invoices,
          technical notices, updates, security alerts, and support and administrative
          messages;
        </li>
        <li>
          Communicate about promotions, upcoming events, and other news about products and
          services offered by us and our selected partners;
        </li>
        <li>Link or combine user information with other personal information;</li>
        <li>
          Protect, investigate, and deter against fraudulent, unauthorized, or illegal
          activity; and
        </li>
        <li>Provide and deliver products and services customers request.</li>
      </ul>
      <div className="privacyPolicyModal__sectionHeader">
        <h3>Sharing of Personal Information</h3>
      </div>
      <p>We may share personal information in the following ways:</p>
      <ul>
        <li>
          With your consent (for example, you may let us share personal information with
          others for their own marketing uses--those uses will be subject to their privacy
          policies);
        </li>
        <li>
          When we do a business deal, or negotiate a business deal, involving the sale or
          transfer of all or a part of our business or assets (these deals can include any
          merger, financing, acquisition, or bankruptcy transaction or proceeding);
        </li>
        <li>
          For legal, protection, and safety purposes, such as for the following purposes:
          <ul>
            <li>To comply with laws;</li>
            <li>To respond to lawful requests and legal processes;</li>
            <li>
              To protect the rights and property of ProFormaly, LLC, our agents,
              affiliates, customers, and others--this includes enforcing our agreements,
              policies, and terms of use; and
            </li>
            <li>
              In an emergency--this includes protecting the safety of our employees and
              agents, our customers, or any person;
            </li>
          </ul>
        </li>
        <li>With those who need it to do work for us.</li>
      </ul>
      <p>
        We may also share aggregated and/or anonymized data with others for their own
        uses.
      </p>
      <div className="privacyPolicyModal__sectionHeader">
        <h3>INFORMATION CHOICES AND CHANGES</h3>
      </div>
      <p>
        Our marketing emails tell you how to “opt-out.” If you opt out, we may still send
        you non-marketing emails. Non-marketing emails include emails about your accounts
        and our business dealings with you.
      </p>
      <p>
        You may send requests about personal information to our Contact Information below.
        You can request to change contact choices, opt-out of our sharing with others, and
        update your personal information.
      </p>
      <p>
        You can typically remove and reject cookies from our Site with your browser
        settings. Many browsers are set to accept cookies until you change your settings.
        If you remove or reject our cookies, it could affect how our Site works for you.
      </p>
      <p>
        <strong className="privacyPolicyModal__veryStrong">Contact Information.</strong>{' '}
        We welcome your comments or questions about this privacy policy. You may also
        contact us at our address:
      </p>
      <p>
        Savvi Technologies, Inc.
        <br />
        560 S. 100 W. Suite 16
        <br />
        Orem, UT 84057
      </p>
      <p>
        <strong className="privacyPolicyModal__veryStrong">
          Changes To This Privacy Policy.
        </strong>{' '}
        We may change this privacy policy. If we make any changes, we will change the Last
        Updated date above.
      </p>
    </MyModal>
  );
};

export default PrivacyPolicyModal;
