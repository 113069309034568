import React from 'react';
import { useLocation } from 'react-router';

import paperOSIcon from '../../assets/images/paperOS_icon.png';
import { Button } from '../common';

import './Footer.scss';
import { useSelector } from 'react-redux';
import { BrandMeta } from '../../redux/modules/UI/selectors';

const Footer = ({ hideBack, hideLogo, hideSettings }) => {
  const location = useLocation();
  const brandMeta = useSelector(BrandMeta);
  const { support_email, assets = {} } = brandMeta || {};

  return (
    <div className="footer__main">
      <ul className="footer__list">
        <li className="footer__list-item">
          <img
            className="footer__logo"
            src={assets?.thumbnail || paperOSIcon}
            alt="footer logo"
          />
        </li>
        {/* {isWhiteLabel && (
          <li className="footer__list-item">
            <Button
              buttonType="link"
              to={{
                pathname: '/terms',
                state: {
                  background: {
                    ...location,
                    state: { ...(location.state || {}), initialized: true },
                  },
                },
              }}
              size="sm"
            >
              About paperOS
            </Button>
          </li>
        )} */}
        <li className="footer__list-item">
          <Button
            buttonType="link"
            to={{
              pathname: '/terms',
              state: {
                background: {
                  ...location,
                  state: { ...(location.state || {}), initialized: true },
                },
              },
            }}
            size="sm"
          >
            Terms of Use
          </Button>
        </li>
        <li className="footer__list-item">
          <Button
            buttonType="link"
            size="xs"
            to={{
              pathname: '/privacy',
              state: {
                background: {
                  ...location,
                  state: { ...(location.state || {}), initialized: true },
                },
              },
            }}
          >
            Privacy Policy
          </Button>
          {/* <a
            href="http://docs.wixstatic.com/ugd/342422_2fcd1c73409a443d988a73a2fb263716.pdf"
            target="_blank"
            rel="noopener noreferrer"
            className="footer__link"
          >
            Privacy Policy
          </a> */}
        </li>
        <li className="footer__list-item">
          <Button
            buttonType="link"
            size="xs"
            href={`mailto:${support_email || 'support@paperos.com'}`}
            target="_blank"
            rel="noopener noreferrer"
            className="footer__link"
          >
            Contact Support
          </Button>
        </li>
      </ul>
    </div>
  );
};

Footer.defaultProps = {
  isAuthenticated: false,
};

export default Footer;
