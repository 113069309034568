import * as yup from 'yup';

const requestSchema = yup.object().shape({
  category: yup.string().required().label('Category'),
  description: yup.string().required().label('Request Details'),
});

const trademarkSchema = yup.object().shape({
  phrase: yup.string().required().label('Name or Phrase'),
  industry: yup.string().required().label('Industry'),
  clients: yup.string().required().label('Clients'),
  services: yup.string().required().label('Products or Services'),
  type: yup.string().required().label('Trademark Type'),
  description: yup.string().required().label('Description'),
});

const loginSchema = yup.object().shape({
  email: yup.string().email().required().label('E-mail'),
  password: yup.string().min(6).required().label('Password'),
});

const forgotSchema = yup.object().shape({
  email: yup.string().email().required().label('E-mail'),
});

const resetSchema = yup.object().shape({
  password: yup.string().min(6).required().label('Password'),
});

const settingsSchema = yup.object().shape({
  first_name: yup.string().required().label('First Name'),
  last_name: yup.string().required().label('Last Name'),
  phone: yup
    .string()
    .test(
      'Phone',
      'Phone number is not valid, should be a valid  US Phone, (i.e. (555) 555-5555',
      value =>
        !value ||
        value.match(/^[+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/),
    )
    .label('Phone')
    .nullable(),
});

const passwordSchema = yup.object().shape({
  oldPassword: yup.string().min(6).required().label('Password'),
  newPassword: yup.string().min(12).required().label('Password'),
  phone: yup
    .string()
    .test(
      'Phone',
      'Phone number is not valid, should be a valid  US Phone, (i.e. (555) 555-5555',
      value =>
        !value ||
        value.match(/^[+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/),
    )
    .label('Phone'),
});

const addressSchema = yup.object().shape({
  lineOne: yup.string().required().label('Line One'),
  lineTwo: yup.string().label('Line Two'),
  city: yup.string().required().label('City'),
  state: yup.string().required().label('State'),
  zip: yup.string().required().label('ZIPCode'),
});

const phoneSchema = yup
  .string()
  .test(
    'Phone',
    'Phone number is not valid, should be a valid  US Phone, (i.e. (555) 555-5555',
    value =>
      !value || value.match(/^[+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/),
  )
  .label('Phone');

const validateField = (input, formSchema, errors) => {
  if (!errors[input.name]) return;

  const { name, value } = input;
  const schema = yup.reach(formSchema, name);

  try {
    return !schema.validateSync(value);
  } catch (err) {
    if (err.name === 'ValidationError') {
      return { [name]: err.message };
    }
  }
};

const validateForm = (schema, inputs) => {
  try {
    return !schema.validateSync(inputs, { abortEarly: false });
  } catch (err) {
    if (err.name === 'ValidationError') {
      const errors = err.inner.reduce((memo, { path, message }) => {
        return {
          ...memo,
          [path]: message,
        };
      }, {});
      return errors;
    }
  }
};

const registerSchema = yup.object().shape({
  first_name: yup.string().required().label('First Name'),
  last_name: yup.string().required().label('Last Name'),
  email: yup.string().email().required().label('E-mail'),
  company_name: yup.string().required().label('Company Name'),
  password: yup.string().min(12).required().label('Password'),
  tos: yup
    .boolean()
    .oneOf([true], "You must agree to PaperOS's Terms of Service")
    .required(),
});

const addPaymentMethodFormSchema = yup.object().shape({
  name: yup.string().required().label('Card holder name'),
  is_default: yup.string().label('Is default'),
});

const addACHFormSchema = yup.object().shape({
  account_holder_name: yup.string().required().label('Account holder name'),
  routing_number: yup.string().required().label('Routing number'),
  account_number: yup.string().required().label('Bank account number'),
  bank_label: yup.string().required().label('Bank account name'),
});

const verifyACHFormSchema = yup.object().shape({
  account_holder_name: yup.string().label('Account holder name'),
  routing_number: yup.string().label('Routing number'),
  account_number: yup.string().label('Bank account number'),
  amount_one: yup.number().positive().required().label('Amount One'),
  amount_two: yup.number().positive().required().label('Amount Two'),
  is_default: yup.string().label('Is default'),
});

const onboardingFormSchema = yup.object().shape({
  employees_estimate: yup.string().required().nullable().label('Employees Number'),
  buisness_type: yup.string().required().nullable().label('Company Business Type'),
  referral_type: yup.string().required().nullable().label('Referral Type'),
  interests: yup.array().required().label('Company Services'),
  other_legal_needs: yup.string().label('Other Company Services'),
});

// const createValidation = (initValues) => {
//   let schema = lazy(initValues, (res, val, index) => {

//   })

// }

// import mapValues from 'lodash-es/mapValues';

// Yup.lazy(obj =>
//   Yup.object(
//     mapValues(obj, (value, key) => {
//       if (key === 'owner') {
//         return Yup.string().required();
//       }
//       if (key === 'age') {
//         return Yup. number();
//       }
//       if (key.includes('propertyName')) {
//         return Yup.string().required();
//       }
//       if (key.includes('propertyValue')) {
//         return Yup.number().required();
//       }
//     })
//   )
// )

const forms = {
  addACHForm: addACHFormSchema,
  addPaymentMethodForm: addPaymentMethodFormSchema,
  onboardingForm: onboardingFormSchema,
  registerForm: registerSchema,
  verifyACHForm: verifyACHFormSchema,
};

export {
  addressSchema,
  forgotSchema,
  forms,
  loginSchema,
  passwordSchema,
  phoneSchema,
  registerSchema,
  requestSchema,
  resetSchema,
  settingsSchema,
  trademarkSchema,
  // createValidation,
  validateField,
  validateForm,
};
