import { RSAA } from 'redux-api-middleware';

// import filter from 'lodash-es/filter';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';

import { requestHeaders } from '../../utils/api';
import { getAccountInfo } from '../Formation/operations';
import { getAccountProject } from '../Project/operations';
import * as actions from '../Transaction/actions';
import { setNotice } from '../UI/actions';
import * as Types from './types';
import { isObject } from 'lodash-es';
import { generateErrorNotice } from '../../../utils/generateErrorNotice';

// export const getQuestionnaire = (questionnaireId, taskId) => {
//   const REQUEST = Types[`GET_QUESTIONNAIRE_REQUEST`];
//   const SUCCESS = Types[`GET_QUESTIONNAIRE_SUCCESS`];
//   const FAILED = Types[`GET_QUESTIONNAIRE_FAILED`];
//   return async (dispatch, getState) => {
//     const actionResponse = await dispatch({
//       [RSAA]: {
//         endpoint: `/api/questionnaire/${questionnaireId}`,
//         method: 'GET',
//         headers: requestHeaders(true),
//         types: [REQUEST, SUCCESS, FAILED],
//       },
//     });
//     if (!actionResponse.error) {
//       await dispatch(actions.setQuestionnaire(actionResponse.payload));
//     }
//   };
// };

export const getAccountTransactions = moduleId => {
  const REQUEST = Types[`GET_TRANSACTIONS_BY_MODULE_REQUEST`];
  const SUCCESS = Types[`GET_TRANSACTIONS_BY_MODULE_SUCCESS`];
  const FAILED = Types[`GET_TRANSACTIONS_BY_MODULE_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/transaction?limit=400',
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await dispatch(
        actions.setTransactionsByModule(actionResponse.payload, moduleId || '-1'),
      );
    } else {
      throw actionResponse.payload.message;
    }
  };
};

export const getTransactionsHistory = moduleId => {
  const REQUEST = Types[`GET_TRANSACTIONS_BY_MODULE_REQUEST`];
  const SUCCESS = Types[`GET_TRANSACTIONS_BY_MODULE_SUCCESS`];
  const FAILED = Types[`GET_TRANSACTIONS_BY_MODULE_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/transaction?include_history=1&limit=900',
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await dispatch(
        actions.setTransactionsByModule(actionResponse.payload, moduleId),
      );
    } else {
      dispatch(setNotice(generateErrorNotice(actionResponse.payload)));
      throw actionResponse.payload.message;
    }
  };
};

export const getTransaction = transactionId => {
  const REQUEST = Types[`GET_TRANSACTION_REQUEST`];
  const SUCCESS = Types[`GET_TRANSACTION_SUCCESS`];
  const FAILED = Types[`GET_TRANSACTION_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/transaction/${transactionId}`,
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error && !isEmpty(actionResponse.payload)) {
      await dispatch(actions.setTransaction(actionResponse.payload));
      return await actionResponse.payload;
    } else {
      throw await actionResponse.payload.message;
    }
  };
};

export const createTransaction = (projectId, templateId) => {
  const REQUEST = Types[`POST_TRANSACTION_REQUEST`];
  const SUCCESS = Types[`POST_TRANSACTION_SUCCESS`];
  const FAILED = Types[`POST_TRANSACTION_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/project/${projectId}/transaction_template/${templateId}`,
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error && !isEmpty(actionResponse.payload)) {
      await dispatch(actions.setTransaction(actionResponse.payload));
      return await actionResponse.payload;
    } else {
      throw await actionResponse.payload.message;
    }
  };
};

export const getAllAccountTransactions = (queryString = '') => {
  const REQUEST = Types[`GET_ALL_TRANSACTIONS_REQUEST`];
  const SUCCESS = Types[`GET_ALL_TRANSACTIONS_SUCCESS`];
  const FAILED = Types[`GET_ALL_TRANSACTIONS_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/user/transaction${queryString}`,
        method: 'GET',
        headers: requestHeaders(true, 'user'),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error && Array.isArray(actionResponse.payload)) {
      await dispatch(actions.setMyTransactions(actionResponse.payload));
      return await actionResponse.payload;
    } else {
      throw await actionResponse.payload.message;
    }
  };
};

export const getTransactionByTemplateId = templateId => {
  const REQUEST = Types[`GET_TRANSACTION_BY_TEMPLATE_ID_REQUEST`];
  const SUCCESS = Types[`GET_TRANSACTION_BY_TEMPLATE_ID_SUCCESS`];
  const FAILED = Types[`GET_TRANSACTION_BY_TEMPLATE_ID_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/transaction_template/${templateId}`,
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(actions.setTransaction(actionResponse.payload));
      return await actionResponse.payload;
    } else {
      throw actionResponse.payload.message;
    }
  };
};

export const getTransactionPreview = transactionId => {
  const REQUEST = Types[`GET_TRANSACTION_PREVIEW_REQUEST`];
  const SUCCESS = Types[`GET_TRANSACTION_PREVIEW_SUCCESS`];
  const FAILED = Types[`GET_TRANSACTION_PREVIEW_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/transaction/${transactionId}/preview`,
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await dispatch(
        actions.setTransactionPreview(transactionId, actionResponse.payload),
      );
    } else {
      throw actionResponse.payload.message;
    }
  };
};

export const deleteDocumentUpload = (boxFileId, transactionId, accessCode) => {
  const REQUEST = Types[`DELETE_DOCUMENT_UPLOAD_REQUEST`];
  const SUCCESS = Types[`DELETE_DOCUMENT_UPLOAD_SUCCESS`];
  const FAILED = Types[`DELETE_DOCUMENT_UPLOAD_FAILED`];
  return async (dispatch, getState) => {
    dispatch(actions.clearDocumentError());
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/box/file/${boxFileId}`,
        method: 'DELETE',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice({ type: 'warning', message: 'Upload removed.' }));
      if (transactionId) {
        return await dispatch(getTransactionQuestionnaire(transactionId));
      } else if (accessCode) {
        return await dispatch(getOutsideForm(accessCode));
      }
    } else {
      await dispatch(
        setNotice({
          type: 'error',
          message: get(actionResponse, 'payload.message', 'Error removing upload.'),
        }),
      );
      await dispatch(
        actions.setDocumentError(
          get(
            actionResponse,
            'error',
            'An error has occurred loading your documents. Go ahead and refresh your page. If the documents are still not loading correctly, please contact us.',
          ),
        ),
      );
      throw await get(actionResponse, 'payload.message', 'An error has occurred');
    }
  };
};

export const resendLinkEmail = (docId, email, accountId) => {
  const REQUEST = Types[`RESEND_LINK_EMAIL_REQUEST`];
  const SUCCESS = Types[`RESEND_LINK_EMAIL_SUCCESS`];
  const FAILED = Types[`RESEND_LINK_EMAIL_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/pandadoc/documents/${docId}/resend`,
        method: 'post',
        headers: requestHeaders(true, accountId),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify({ email }),
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice('Link Sent'));
    } else {
      dispatch(setNotice({ type: 'error', message: 'Error resending email link.' }));
    }
  };
};

export const getTransactionDocuments = transactionId => {
  const REQUEST = Types[`GET_TRANSACTION_DOCUMENTS_REQUEST`];
  const SUCCESS = Types[`GET_TRANSACTION_DOCUMENTS_SUCCESS`];
  const FAILED = Types[`GET_TRANSACTION_DOCUMENTS_FAILED`];
  return async (dispatch, getState) => {
    await dispatch(actions.clearDocumentError());
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/transaction/${transactionId}/documents`,
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await dispatch(
        actions.setDocumentsByTransaction(actionResponse.payload, transactionId),
      );
      // return actionResponse.payload;
    } else {
      await dispatch(
        actions.setDocumentError(
          get(
            actionResponse,
            'error',
            'An error has occurred loading your documents. Go ahead and refresh your page. If the documents are still not loading correctly, please contact us.',
          ),
        ),
      );
      throw await get(actionResponse, 'payload.message', 'An error has occurred');
    }
  };
};

export const setDocumentReviewer = (pandaDocId, body, transactionId) => {
  const REQUEST = Types[`SET_DOCUMENT_REVIEWER_REQUEST`];
  const SUCCESS = Types[`SET_DOCUMENT_REVIEWER_SUCCESS`];
  const FAILED = Types[`SET_DOCUMENT_REVIEWER_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/pandadoc/documents/${pandaDocId}/reviewer`,
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      dispatch(
        setNotice(get(actionResponse, 'payload.message', 'Document Reviewer Set')),
      );
      await dispatch(getTransactionDocuments(transactionId));
      return await actionResponse.payload;
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
      throw actionResponse.payload.message;
    }
  };
};

export const setDocumentRedliningVersion = (document_id, uuid, transactionId) => {
  const REQUEST = Types[`POST_REDLINING_VERSION_REQUEST`];
  const SUCCESS = Types[`POST_REDLINING_VERSION_SUCCESS`];
  const FAILED = Types[`POST_REDLINING_VERSION_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/pandadoc/documents/${document_id}/finalize/${uuid}`,
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await dispatch(getTransactionDocuments(transactionId));
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
      throw actionResponse.payload.message;
    }
  };
};

export const approveDocumentSuggestions = (document_id, transactionId) => {
  const REQUEST = Types[`APPROVE_DOCUMENT_SUGGESTIONS_REQUEST`];
  const SUCCESS = Types[`APPROVE_DOCUMENT_SUGGESTIONS_SUCCESS`];
  const FAILED = Types[`APPROVE_DOCUMENT_SUGGESTIONS_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/pandadoc/documents/${document_id}/redline`,
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await dispatch(getTransactionDocuments(transactionId));
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
      throw actionResponse.payload.message;
    }
  };
};

export const updateDocumentSuggestions = (document_id, body, transactionId) => {
  const REQUEST = Types[`UPDATE_DOCUMENT_SUGGESTIONS_REQUEST`];
  const SUCCESS = Types[`UPDATE_DOCUMENT_SUGGESTIONS_SUCCESS`];
  const FAILED = Types[`UPDATE_DOCUMENT_SUGGESTIONS_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/pandadoc/documents/${document_id}/redline`,
        method: 'PATCH',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      return await dispatch(getTransactionDocuments(transactionId));
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
      throw actionResponse.payload.message;
    }
  };
};

export const updateTransaction = (transactionId, body) => {
  const REQUEST = Types[`UPDATE_TRANSACTION_REQUEST`];
  const SUCCESS = Types[`UPDATE_TRANSACTION_SUCCESS`];
  const FAILED = Types[`UPDATE_TRANSACTION_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/transaction/${transactionId}`,
        method: 'PUT',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      return await dispatch(getTransaction(transactionId));
    } else {
      dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
      throw actionResponse.payload.message;
    }
  };
};

export const getOutsideForm = access_code => {
  const REQUEST = Types[`GET_OUTSIDE_FORM_REQUEST`];
  const SUCCESS = Types[`GET_OUTSIDE_FORM_SUCCESS`];
  const FAILED = Types[`GET_OUTSIDE_FORM_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/public/outside_form/${access_code}`,
        method: 'GET',
        headers: requestHeaders(),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      if (
        get(actionResponse, 'payload.message', '') ===
        'This form has already been completed.  Please contact technical support if you believe this is in error.'
      ) {
        throw await actionResponse.payload.message;
      }
      return await dispatch(
        actions.setTransactionQuestionnaire(actionResponse.payload, access_code),
      );
    }
    throw await get(
      actionResponse,
      'payload.message',
      'This form has already been completed.  Please contact technical support if you believe this is in error.',
    );
  };
};

export const createOutsideForm = (body, transactionId) => {
  const REQUEST = Types[`CREATE_OUTSIDE_FORM_REQUEST`];
  const SUCCESS = Types[`CREATE_OUTSIDE_FORM_SUCCESS`];
  const FAILED = Types[`CREATE_OUTSIDE_FORM_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/transaction/${transactionId}/outside`,
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      dispatch(setNotice('Shared Form generated.'));
      dispatch(getTransaction(transactionId));
      return await actionResponse.payload;
    }
    dispatch(
      setNotice({
        type: 'error',
        message: get(
          actionResponse,
          'payload.message',
          'Failed to generate Shared Form.',
        ),
      }),
    );
    throw await get(
      actionResponse,
      'payload.message',
      'This form has already been completed.  Please contact technical support if you believe this is in error.',
    );
  };
};

export const getTransactionQuestionnaire = transactionId => {
  const REQUEST = Types[`GET_TRANSACTION_QUESTIONNAIRE_REQUEST`];
  const SUCCESS = Types[`GET_TRANSACTION_QUESTIONNAIRE_SUCCESS`];
  const FAILED = Types[`GET_TRANSACTION_QUESTIONNAIRE_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/transaction/${transactionId}/questionnaire`,
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      await dispatch(
        actions.setTransactionQuestionnaire(actionResponse.payload, transactionId),
      );
      return await actionResponse.payload;
    }
    throw await get(
      actionResponse,
      'payload.message',
      'This form has already been completed.  Please contact technical support if you believe this is in error.',
    );
  };
};

export const getMyTemplates = () => {
  const REQUEST = Types['GET_MY_TEMPLATES_REQUEST'];
  const SUCCESS = Types['GET_MY_TEMPLATES_SUCCESS'];
  const FAILED = Types['GET_MY_TEMPLATES_FAILED'];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/storage/my_templates',
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await dispatch(actions.setMyTemplates(actionResponse.payload));
    }
    throw await get(
      actionResponse,
      'payload.message',
      'This form has already been completed.  Please contact technical support if you believe this is in error.',
    );
  };
};

export const getCustomDocument = (id, transactionId) => {
  const REQUEST = Types['GET_CUSTOM_DOCUMENT_REQUEST'];
  const SUCCESS = Types['GET_CUSTOM_DOCUMENT_SUCCESS'];
  const FAILED = Types['GET_CUSTOM_DOCUMENT_FAILED'];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/custom_document/${id}`,
        method: 'GET',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error) {
      return await dispatch(
        actions.addCustomDocument(actionResponse.payload, transactionId),
      );
    }
    throw await get(
      actionResponse,
      'payload.message',
      'This form has already been completed.  Please contact technical support if you believe this is in error.',
    );
  };
};

export const updateCustomDocument = (body, customDocIndex, transactionId) => {
  const REQUEST = Types['UPDATE_CUSTOM_DOCUMENT_REQUEST'];
  const SUCCESS = Types['UPDATE_CUSTOM_DOCUMENT_SUCCESS'];
  const FAILED = Types['UPDATE_CUSTOM_DOCUMENT_FAILED'];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/custom_document/${body.id}`,
        method: 'PUT',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      dispatch(
        actions.setCustomDocument(actionResponse.payload, customDocIndex, transactionId),
      );
      return await actionResponse.payload;
    }
    throw await get(
      actionResponse,
      'payload.message',
      'This form has already been completed.  Please contact technical support if you believe this is in error.',
    );
  };
};

export const createCustomDocument = (body, transactionId) => {
  const REQUEST = Types['CREATE_CUSTOM_DOCUMENT_REQUEST'];
  const SUCCESS = Types['CREATE_CUSTOM_DOCUMENT_SUCCESS'];
  const FAILED = Types['CREATE_CUSTOM_DOCUMENT_FAILED'];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: '/api/account/custom_document',
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      return await dispatch(getTransactionQuestionnaire(transactionId));
    }
    const message = get(
      actionResponse,
      'payload.message',
      'This form has already been completed.  Please contact technical support if you believe this is in error.',
    );
    dispatch(setNotice({ type: 'error', message }));
    throw await message;
  };
};

export const createFeature = (meta, isOutsideForm) => {
  let {
    access_code,
    value,
    feature_id,
    feature_type_id,
    resource_id,
    resourceTypeId,
    transactionId,
  } = meta;
  return async (dispatch, getState) => {
    const REQUEST = Types[`CREATE_FEATURE_REQUEST`];
    const SUCCESS = Types[`CREATE_FEATURE_SUCCESS`];
    const FAILED = Types[`CREATE_FEATURE_FAILED`];
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/${isOutsideForm ? 'unlisted' : 'account'}/flat_feature`,
        method: 'POST',
        headers: requestHeaders(!isOutsideForm),
        types: [REQUEST, SUCCESS, FAILED],
        body: () =>
          JSON.stringify({
            access_code,
            value,
            feature_type_id,
            resource_id,
            transaction_id: transactionId,
          }),
      },
    });
    if (!actionResponse.error) {
      if (access_code) {
        transactionId = access_code;
      }
      if (feature_id) {
        await dispatch(
          actions.updateResource({
            value,
            feature_id,
            feature_type_id,
            resource_id,
            resourceTypeId,
            transactionId,
          }),
        );
        return await actionResponse.payload;
      }
      await dispatch(
        actions.createResourceFeature({
          feature: isObject(actionResponse.payload.feature)
            ? actionResponse.payload.feature
            : actionResponse.payload,
          resourceTypeId,
          transactionId,
        }),
      );
      return await actionResponse.payload;
    }
    await dispatch(
      setNotice({
        type: 'error',
        message: get(
          actionResponse,
          'payload.message',
          'An error has occurred. If it continues, please contact PaperOS Support.',
        ),
      }),
    );
    throw await actionResponse.payload.message;
  };
};

// export const patchFeature = meta => {
//   const {
//     value,
//     feature_id,
//     feature_type_id,
//     resource_id,
//     resourceTypeId,
//     transactionId,
//   } = meta;
//   return async (dispatch, getState) => {
//     const REQUEST = Types[`PATCH_FEATURE_REQUEST`];
//     const SUCCESS = Types[`PATCH_FEATURE_SUCCESS`];
//     const FAILED = Types[`PATCH_FEATURE_FAILED`];
//     const actionResponse = await dispatch({
//       [RSAA]: {
//         endpoint: `/api/account/flat_feature/${feature_id}`,
//         method: 'PATCH',
//         headers: requestHeaders(true),
//         types: [REQUEST, SUCCESS, FAILED],
//         body: () => JSON.stringify({ value }),
//       },
//     });
//     if (!actionResponse.error) {
//       // dispatch(getTransactionQuestionnaire(transactionId));
//       return await dispatch(
//         actions.updateResource({
//           value,
//           feature_type_id,
//           resource_id,
//           resourceTypeId,
//           transactionId,
//         }),
//       );
//       // return actionResponse;
//     }
//     await dispatch(setNotice({ type: 'error', message: actionResponse.payload.message }));
//     throw await actionResponse.payload.message;
//   };
// };

export const setResourceIds = (transactionId, body) => {
  return async (dispatch, getState) => {
    const REQUEST = Types[`SET_RESOURCE_IDS_REQUEST`];
    const SUCCESS = Types[`SET_RESOURCE_IDS_SUCCESS`];
    const FAILED = Types[`SET_RESOURCE_IDS_FAILED`];
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/transaction/${transactionId}/set_resource`,
        method: 'PUT',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      return await dispatch(getTransactionQuestionnaire(transactionId));
      // dispatch(actions.patchFeature(actionResponse.payload, individualName));
      // dispatch(
      //   setNotice(`${individualName}'s ${lowerCase(featureName)} updated.`),
      // );
    } else {
      await dispatch(
        setNotice({ type: 'error', message: actionResponse.payload.message }),
      );
      throw await actionResponse.payload.message;
    }
  };
};

export const createResourceName = (body, accountId) => {
  return async (dispatch, getState) => {
    const REQUEST = Types[`CREATE_RESOURCE_NAME_REQUEST`];
    const SUCCESS = Types[`CREATE_RESOURCE_NAME_SUCCESS`];
    const FAILED = Types[`CREATE_RESOURCE_NAME_FAILED`];
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/resource`,
        method: 'POST',
        headers: requestHeaders(true, accountId),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      return await actionResponse.payload;
      // dispatch(actions.patchFeature(actionResponse.payload, individualName));
      // dispatch(
      //   setNotice(`${individualName}'s ${lowerCase(featureName)} updated.`),
      // );
    } else {
      throw await actionResponse.payload;
    }
  };
};

export const updateResourceName = (body, resourceId, isOutside, accountId) => {
  return async (dispatch, getState) => {
    const REQUEST = Types[`UPDATE_RESOURCE_NAME_REQUEST`];
    const SUCCESS = Types[`UPDATE_RESOURCE_NAME_SUCCESS`];
    const FAILED = Types[`UPDATE_RESOURCE_NAME_FAILED`];
    const privacy = isOutside ? 'unlisted' : 'account';
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/${privacy}/resource/${resourceId}`,
        method: 'PATCH',
        headers: requestHeaders(!isOutside, accountId),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      // dispatch(actions.patchFeature(actionResponse.payload, individualName));
      // dispatch(
      //   setNotice(`${individualName}'s ${lowerCase(featureName)} updated.`),
      // );
      return await actionResponse.payload;
    } else {
      await dispatch(
        setNotice({ type: 'error', message: actionResponse.payload.message }),
      );
      throw actionResponse.payload;
    }
  };
};

export const finalizeTransaction = (transactionId, projectId, body, dev_pandadoc) => {
  const isDevEnv = process.env.REACT_APP_LOG_ENV !== 'production';
  if (isDevEnv) {
    body = {
      ...body,
      dev_pandadoc,
    };
  }
  return async (dispatch, getState) => {
    const REQUEST = Types[`FINALIZE_TRANSACTION_REQUEST`];
    const SUCCESS = Types[`FINALIZE_TRANSACTION_SUCCESS`];
    const FAILED = Types[`FINALIZE_TRANSACTION_FAILED`];
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/transaction/${transactionId}/finalize`,
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
        body: () => JSON.stringify(body),
      },
    });
    if (!actionResponse.error) {
      if (projectId) {
        await dispatch(getAccountProject(projectId));
      }
      await dispatch(getAccountInfo());
      await dispatch(setNotice('Task Submitted!'));
      return await dispatch(getTransaction(transactionId));
      // dispatch(actions.patchFeature(actionResponse.payload, individualName));
    } else {
      if (
        actionResponse.payload.message ===
        'Account does not have the correct subscription'
      ) {
        await dispatch(
          setNotice({
            type: 'error',
            message:
              actionResponse.payload.message +
              '.  Click Subscription link underneath pricing',
          }),
        );
        throw await `${actionResponse.payload.message}. Click Subscription link underneath pricing`;
      } else if (actionResponse.payload.code === 'STRIPE_CARD_ERROR_CARD_DECLINED') {
        const message = 'Card Declined';
        await dispatch(
          setNotice({
            type: 'error',
            message,
          }),
        );
        throw new Error(message);
      } else {
        await dispatch(
          setNotice({
            type: 'error',
            message: get(
              actionResponse,
              'payload.message',
              'An error Has occurred. If error persists, please contact us.',
            ),
          }),
        );
        throw await get(
          actionResponse,
          'payload.message',
          'An error Has occurred. If error persists, please contact us.',
        );
      }
    }
  };
};

export const finalizeOutsideForm = (access_code, finalize_snapshot) => {
  return async (dispatch, getState) => {
    const REQUEST = Types[`FINALIZE_TRANSACTION_REQUEST`];
    const SUCCESS = Types[`FINALIZE_TRANSACTION_SUCCESS`];
    const FAILED = Types[`FINALIZE_TRANSACTION_FAILED`];
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/public/outside_form/${access_code}/finalize`,
        method: 'POST',
        headers: requestHeaders(),
        types: [REQUEST, SUCCESS, FAILED],
        body: () =>
          JSON.stringify({
            finalize_snapshot,
          }),
      },
    });
    if (!actionResponse.error) {
      await dispatch(setNotice('Outside Form Submitted'));
      return await actionResponse.payload;
      // dispatch(actions.patchFeature(actionResponse.payload, individualName));
    } else {
      if (
        actionResponse.payload.message ===
        'Account does not have the correct subscription'
      ) {
        await dispatch(
          setNotice({
            type: 'error',
            message:
              actionResponse.payload.message +
              '.  Click Subscription link underneath pricing',
          }),
        );
        throw await `${actionResponse.payload.message}. Click Subscription link underneath pricing`;
      } else {
        await dispatch(
          setNotice({
            type: 'error',
            message: get(
              actionResponse,
              'payload.message',
              'An error Has occurred. If error persists, please contact us.',
            ),
          }),
        );
        throw await get(
          actionResponse,
          'payload.message',
          'An error Has occurred. If error persists, please contact us.',
        );
      }
    }
  };
};

export const generateBatchTransactions = (
  transactionTemplateId,
  boxFileId,
  projectId,
) => {
  const REQUEST = Types[`POST_BATCH_TRANSACTIONS_REQUEST`];
  const SUCCESS = Types[`POST_BATCH_TRANSACTIONS_SUCCESS`];
  const FAILED = Types[`POST_BATCH_TRANSACTIONS_FAILED`];
  return async (dispatch, getState) => {
    const actionResponse = await dispatch({
      [RSAA]: {
        endpoint: `/api/account/transaction/${transactionTemplateId}/batch/${boxFileId}/project/${projectId}?finalize=true`,
        method: 'POST',
        headers: requestHeaders(true),
        types: [REQUEST, SUCCESS, FAILED],
      },
    });
    if (!actionResponse.error && !isEmpty(actionResponse.payload)) {
      return await actionResponse.payload;
    } else {
      dispatch(setNotice(generateErrorNotice(actionResponse.payload)));
      throw await actionResponse.payload.message;
    }
  };
};
